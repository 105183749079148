import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";

const DisclosureBanner = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;
	const API_TOKEN = process.env.REACT_APP_API_TOKEN;

	const DisclosureBanner = useCallback(async () => {
		const query = `
      query {
        disclosureOfInformation(locale: "${language}") {
          Title
          Banner_Image {
            Title
            Image {
             	url
				formats
            }
          }
        }
      }
    `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);

			const result = await response.json();
			setData(result.data.disclosureOfInformation);
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, API_TOKEN, language]);

	useEffect(() => {
		DisclosureBanner();
	}, [DisclosureBanner]);

	if (!data)
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);

	const { Title, Banner_Image } = data;
	// Handle optional chaining and URL construction for the background image
	const backgroundImage = `${API_URL}${
		Banner_Image?.Image?.formats?.large?.url || Banner_Image?.Image?.url || ""
	}`;

	return (
		<div className="relative">
			{/* Section Gambar */}
			<section
				className="relative bg-cover bg-center h-[40vh] z-10"
				style={{ backgroundImage: `url(${backgroundImage})` }}
			></section>

			{/* Section Tulisan yang menutupi gambar */}
			<div className="absolute top-0 left-0 w-full h-[40vh] flex items-center justify-center z-20 font-helvetica">
				<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
					<div className="container mx-auto p-2 lg:p-4">
						<section className="mb-12">
							{/* Overlay */}
							<div className="absolute inset-0 bg-blue-900 bg-opacity-50 z-10"></div>

							{/* Text Container */}
							<div className="relative flex h-full z-20 p-2 w-full">
								{/* Middle Column */}
								<div className="flex flex-1 items-center justify-start mt-[15vh]">
									<div className="text-white text-start">
										<h1 className="text-2xl md:text-4xl font-bold mb-2">
											{Title}
										</h1>
										<nav className="text-sm md:text-base">
											<span className="text-white opacity-80">Home</span>
											<span className="mx-1">›</span>
											<span className="text-white opacity-80">Investor</span>
											<span className="mx-1">›</span>
											<span className="text-white">{Title}</span>
										</nav>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DisclosureBanner;
