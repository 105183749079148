// LanguageContext.js
import React, { createContext, useContext, useState } from "react";

// Membuat context untuk bahasa
const LanguageContext = createContext();

// Hook untuk mengakses context
export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
	const [language, setLanguage] = useState("id");

	// Fungsi untuk mengganti bahasa
	const toggleLanguage = (lang) => {
		setLanguage(lang);
	};

	return (
		<LanguageContext.Provider value={{ language, toggleLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
};
