// App.js
import React from "react";
import Navbar from "../../../../components/Navbar.js";
import Footer from "../../../../components/Footer.js";
import Banner from "./BannerNomination.js";
import Content from "./ContentNomination.js.js";
import "../../../../App.css"; // Import file CSS untuk gaya yang sama

function App() {
	return (
		<div className="App">
			<Navbar />
			<Banner />
			<Content />
			<Footer />
		</div>
	);
}

export default App;
