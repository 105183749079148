import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../../context/LanguageContext";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Icon from "../../../../asset/Vector.png";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-2xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const ESGPage = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [auditFiles, setAuditFiles] = useState([]);
	const [reportFiles, setReportFiles] = useState([]);
	const [seoData, setSeoData] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedPerson, setSelectedPerson] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `query { generalPage { SEO { MetaTitle MetaDescription ShareImage { url } MetaSocial { Title Social_Network Description Image { url } } Keywords MetaRobots StructuredData MetaViewPort CanonicalURL }}}`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (response.ok) {
				const result = await response.json();
				setSeoData(result.data.generalPage.SEO);
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchESGPage = useCallback(async () => {
		const query = `
        query {
            bocEsgCommittee(locale: "${language}") {
                Description
                Sub_Title
                ESG_IndependenceDescription
                ESG_Member {
                    Name
                    Position
                    Short_Description
                    Long_Description
                    Image {
                        Title
                        Image {
                            url
                            formats
                        }
                    }
                }
                SEO {
                    Use_GeneralSEO
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage {
                            url
                        }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image {
                                url
                            }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        }
    `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});

			if (response.ok) {
				const result = await response.json();
				const fetchShareholding = result.data.bocEsgCommittee;
				setData(fetchShareholding);

				if (fetchShareholding.SEO.Use_GeneralSEO) {
					fetchGeneralSEOData();
				} else {
					setSeoData(fetchShareholding.SEO.SEO);
				}
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error(
				"Error fetching board of commissioners data:",
				error.message
			);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const fetchESGPageIndependenceFiles = useCallback(async () => {
		const query = `
            query esgCommitteeIndependenceFiles {
                esgCommitteeIndependenceFiles (locale: "${language}"){
                    Title
                    File {
                        url
                    }
                }
            }
        `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (response.ok) {
				const result = await response.json();
				setAuditFiles(result.data.esgCommitteeIndependenceFiles);
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error(
				"Error fetching audit committee independence files:",
				error.message
			);
		}
	}, [API_URL, language]);

	const fetchESGPageDocumentReports = useCallback(async () => {
		const query = `
            query esgCommitteeDocumentReports {
                esgCommitteeDocumentReports (locale: "${language}"){
                    Title
                    File {
                        url
                    }
                }
            }
        `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (response.ok) {
				const result = await response.json();
				setReportFiles(result.data.esgCommitteeDocumentReports);
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error(
				"Error fetching audit committee document reports:",
				error.message
			);
		}
	}, [API_URL, language]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={seoData?.MetaDescription || "Default description"}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchESGPage();
		fetchESGPageIndependenceFiles();
		fetchESGPageDocumentReports();
	}, [
		fetchESGPage,
		fetchESGPageIndependenceFiles,
		fetchESGPageDocumentReports,
	]);

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const openModal = (person) => {
		setSelectedPerson(person);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedPerson(null);
	};

	return (
		<div className="relative w-full h-full relative z-20 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-8 md:mt-6 mt-4">
				<section className="mb-6">
					<div className="flex flex-wrap gap-3 justify-center md:justify-start mb-6">
						<a
							href="/BOC/commitee/audit" // Change this URL
							className="w-full md:w-auto px-3 py-2 rounded-md cursor-pointer bg-gray-300 text-black text-center shadow-md"
							style={{
								fontSize: "1rem",
								maxWidth: "100%", // Full width on mobile
								height: "50px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								textDecoration: "none",
							}}
						>
							{language === "en" ? "Audit Committee" : "Komite Audit"}
						</a>
						<a
							href="/BOC/commitee/Nomination" // Change this URL
							className="w-full md:w-auto px-3 py-2 rounded-md cursor-pointer bg-gray-300 text-black text-center shadow-md"
							style={{
								fontSize: "1rem",
								maxWidth: "100%", // Full width on mobile
								height: "50px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								textDecoration: "none",
							}}
						>
							{language === "en"
								? "Committee of Nomination & Remuneration"
								: "Komite Nominasi & Remunerasi"}
						</a>
						<a
							href="/BOC/commitee/ESG" // Change this URL
							className="w-full md:w-auto px-3 py-2 rounded-md cursor-pointer bg-[#54A7D6] text-white text-center shadow-md"
							style={{
								fontSize: "1rem",
								maxWidth: "100%", // Full width on mobile
								height: "50px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								textDecoration: "none",
							}}
						>
							{language === "en" ? "ESG Committee" : "Komite ESG"}
						</a>
					</div>

					<h2
						className="text-2xl md:text-4xl lg:text-5xl font-bold lg:mb-8 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data.Title}
					</h2>
					{/* Render each board member */}
					{data.ESG_Member.map((member, index) => (
						<div
							className="mb-8 sm:mb-12"
							key={index}
						>
							<h3
								className="lg:text-3xl md:text-2xl text-xl font-semibold text-left"
								style={{
									color: "#2E3491",
									borderBottom: "2px solid #2E3491",
									paddingBottom: "5px",
								}}
							>
								{member.Position}
							</h3>
							<div className="flex flex-col sm:flex-row mt-4 sm:mt-8 items-start">
								<img
									src={`${API_URL}${
										data?.ESG_Member[index]?.Image?.Image?.formats?.small
											?.url || data?.ESG_Member[index]?.Image?.Image?.url
									}`}
									alt={
										data?.ESG_Member[0]?.Image?.Title ||
										"Board of Commissioners"
									}
									className="aspect-[3/4] w-[300px] h-[400px] sm:w-[250px] sm:h-[333px] md:w-[200px] md:h-[266px] lg:w-[300px] lg:h-[400px] object-cover rounded-lg mb-4 sm:mb-0 sm:mr-6 mx-auto"
								/>

								{/* Wrapper untuk deskripsi agar memenuhi ruang yang tersedia */}
								<div className="flex-grow flex flex-col items-start sm:text-left">
									<h4
										className="lg:text-2xl md:text-xl text-xl font-semibold"
										style={{
											color: "#2E3491",
											borderLeft: "4px solid red",
											paddingLeft: "10px",
											marginBottom: "10px",
										}}
									>
										{member.Name}
									</h4>
									{/* Justify text untuk mode mobile */}
									<p className="lg:text-xl md:text-large text-base text-gray-600 mb-4 sm:mb-0 text-justify">
										{member.Short_Description}
									</p>
									<button
										onClick={() => openModal(member)}
										className="text-blue-600 hover:underline mt-0 lg:mt-2 md:mt-2"
									>
										Read More
									</button>
								</div>
							</div>
						</div>
					))}
					{isModalOpen && selectedPerson && (
						<div
							className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-2 sm:px-4 lg:px-10"
							style={{ top: "6rem", md: { top: "5rem" }, lg: { top: "4rem" } }}
						>
							<div
								className="bg-white p-4 sm:p-14 rounded-lg w-full max-w-[95vw] lg:max-w-[85vw] md:max-w-[90vw] max-h-[80vh] sm:max-h-full overflow-y-auto shadow-lg relative min-h-[60vh] sm:min-h-[70vh]"
								role="dialog"
								aria-modal="true"
							>
								<button
									onClick={closeModal}
									aria-label="Close modal"
									className="absolute top-2 right-4 text-gray-600 text-2xl sm:text-3xl font-bold focus:outline-none"
								>
									&times;
								</button>

								{/* Mengatur gambar di atas konten pada mode mobile dan tablet */}
								<div className="flex flex-col md:flex-col lg:flex-row items-center md:items-start gap-4 sm:gap-16">
									<div className="w-full lg:w-1/3 flex justify-center">
										<img
											src={`${API_URL}${
												selectedPerson?.Image?.Image?.formats?.medium?.url ||
												selectedPerson?.Image?.Image?.url
											}`}
											alt={selectedPerson.Name}
											className="w-full max-w-xs sm:max-w-full aspect-[3/4] object-cover rounded-lg mb-4 lg:mb-0 max-h-[420px] md:max-h-[600px] lg:max-h-[700px]" // Menggunakan Tailwind untuk tinggi maksimal gambar di mobile, tablet, dan desktop
										/>
									</div>
									<div className="w-full lg:w-2/3 text-left">
										<h4 className="text-xl sm:text-5xl font-semibold mb-2 sm:mb-6">
											{selectedPerson.Name}
										</h4>
										<MarkdownRenderer
											content={selectedPerson.Long_Description}
											className="text-sm sm:text-base"
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</section>
				<section className="mb-12">
					<h2
						className="text-2xl md:text-4xl lg:text-5xl font-bold lg:mb-8 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data.Sub_Title}
					</h2>
					<MarkdownRenderer content={data.Description}></MarkdownRenderer>
				</section>
				<section className="my-12">
					<h2
						className="text-2xl md:text-4xl lg:text-5xl font-bold lg:mb-8 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{language === "en"
							? "ESG Committee Independence"
							: "Independensi Komite ESG"}
					</h2>
					<MarkdownRenderer
						content={data.ESG_IndependenceDescription}
					></MarkdownRenderer>

					{auditFiles.map((file, index) => (
						<a
							key={index}
							href={`${API_URL}${file.File.url}`}
							rel="noopener noreferrer"
							target="_blank"
							className="lg:text-xl md:text-large text-base flex flex-col md:flex-row items-center md:items-center justify-between border-2 border-blue-900 px-4 py-2 text-blue-900 font-medium rounded mb-4 mt-4"
							style={{ width: "100%", maxWidth: "10000px" }}
						>
							<span className="mb-2 md:mb-0">{file.Title}</span>
							<img
								src={Icon} // Ganti dengan URL atau path gambar yang ingin digunakan
								alt="Download"
								className="w-6 h-6 md:w-8 md:h-8 inline-block md:ml-4" // Sesuaikan ukuran gambar
							/>
						</a>
					))}
				</section>
				<section className="overflow-x-auto my-12">
					<h2
						className="text-2xl md:text-4xl lg:text-5xl font-bold lg:mb-8 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{language === "en" ? "Report" : "Laporan"}
					</h2>
					<table
						className="min-w-full bg-transparent border border-[#2E3491] md:table-fixed"
						style={{
							borderCollapse: "collapse", // Gunakan 'collapse' agar garis terlihat rapi
						}}
					>
						<thead>
							<tr
								className="text-left"
								style={{
									backgroundColor: "#54A7D6",
									borderBottom: "2px solid #2E3491", // Garis tebal di bagian bawah header
								}}
							>
								<th className="px-4 md:px-6 py-2 md:py-4 text-left border-b border-[#2E3491] text-white lg:text-xl md:text-large text-base">
									{language === "en"
										? "ESG Committee Document"
										: "Dokumen Komite ESG"}
								</th>
								<th className="px-4 md:px-6 py-2 md:py-4 text-center border-b border-[#2E3491] text-white lg:text-xl md:text-large text-base">
									{language === "en" ? "Download" : "Unduh"}
								</th>
							</tr>
						</thead>
						<tbody>
							{reportFiles.map((doc, index) => (
								<tr
									key={index}
									className="border-b border-[#2E3491]"
								>
									<td className="px-4 md:px-6 py-2 md:py-4 text-left lg:text-xl md:text-large text-base">
										{doc.Title}
									</td>
									<td className="px-4 md:px-6 py-2 md:py-4 text-center">
										<a
											href={`${API_URL}${doc.File.url}`}
											download
											target="_blank"
										>
											<img
												src={Icon} // Ganti dengan URL atau path gambar yang ingin digunakan
												alt="Download"
												className="w-6 h-6 md:w-8 md:h-8 inline-block" // Sesuaikan ukuran gambar
											/>
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</section>
			</div>
		</div>
	);
};

export default ESGPage;
