import React, { useState, useEffect, useCallback } from "react";
import download from "../../../asset/download.png";
import { Helmet } from "react-helmet";
import { useLanguage } from "../../../context/LanguageContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const CompanyHistory = () => {
	const { language } = useLanguage();
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedYear, setSelectedYear] = useState("");
	const [Anualreport, setAnualreport] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 8;
	const [selectedCategory, setSelectedCategory] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [pageInput, setPageInput] = useState("");
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);
	const [filteranual, setfilteranual] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const getAnualCount = useCallback(async () => {
		const query = `
            query reports_connection {
                reports_connection ( locale: "${language}"){
                    pageInfo {
                        total
                        page
                        pageSize
                        pageCount
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			const result = await response.json();
			setTotalPages(result.data.reports_connection.pageInfo.pageCount);
		} catch (error) {
			console.error("Error fetching report count:", error.message);
		}
	}, [API_URL, language]);

	const fetchAnual = useCallback(
		async (page = 1) => {
			const query = `
        query {
            reports(
                locale: "${language}"
                pagination: { page: ${page}, pageSize: ${itemsPerPage} }
                sort: ["Display_Order:desc", "Date:desc"]
            ) {
                Title
                Date
                Display_Order
                Category
                Cover_Image {
                    Title
                    Image {
                        formats
                        url
                    }
                }
                Document {
                    Title
                    File {
                        url
                    }
                }
            }
        }`;

			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				const result = await response.json();

				const formattedAnualreport = result.data.reports
					.map((item) => ({
						year: new Date(item.Date).getFullYear().toString(),
						description: item.Title,
						date: item.Date,
						displayOrder: item.Display_Order,
						category: item.Category,
						downloadLink: `${API_URL}${item.Document.File.url}` || "#",
						coverImageUrl:
							`${API_URL}${item.Cover_Image?.Image?.url}` ||
							"fallback-image-url",
					}))
					.sort((a, b) => {
						if (b.displayOrder === a.displayOrder) {
							return new Date(b.date) - new Date(a.date);
						}
						return b.displayOrder - a.displayOrder;
					}); // Frontend sort as a backup

				setAnualreport(formattedAnualreport);
			} catch (error) {
				console.error("Error fetching report data:", error.message);
			}
		},
		[API_URL, itemsPerPage, language]
	);

	const FilterAnual = useCallback(async () => {
		const query = `
		query GeneralPage {
			generalPage (locale: "${language}"){
				Show_FilteronAnnualReport
			}
		}
	`;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});

			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);

			const result = await response.json();

			// Destructure response
			const { Show_FilteronAnnualReport } = result.data.generalPage || {};

			// Setting filteranual state if data is available
			setfilteranual(Show_FilteronAnnualReport);
		} catch (error) {
			console.error("Error fetching general Filter data:", error.message);
		}
	}, [API_URL, language]);

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
            query {
                generalPage {
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchShareholding = useCallback(async () => {
		const query = `
			query {
				annualReportAndSustainabilityReport(locale: "${language}") {
					Title
					Description
					SEO {
						Use_GeneralSEO
						SEO {
							MetaTitle
							MetaDescription
							ShareImage { url }
							MetaSocial {
								Title
								Social_Network
								Description
								Image { url }
							}
							Keywords
							MetaRobots
							StructuredData
							MetaViewPort
							CanonicalURL
						}
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.annualReportAndSustainabilityReport;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
	};

	useEffect(() => {
		getAnualCount();
		fetchShareholding();
		FilterAnual();
	}, [getAnualCount, fetchShareholding, FilterAnual]);

	useEffect(() => {
		fetchAnual(currentPage);
	}, [currentPage, fetchAnual]);

	const handlePageInputChange = (event) => {
		setPageInput(event.target.value);
	};

	const handlePageSearch = () => {
		const pageNumber = parseInt(pageInput, 10);
		if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
			setCurrentPage(pageNumber);
			setPageInput("");
		} else {
			alert(`Please enter a page number between 1 and ${totalPages}`);
		}
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
		setSelectedCategory(""); // Reset category filter on page change
	};

	const openModal = (item) => {
		setSelectedItem(item);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedItem(null);
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
		setCurrentPage(1);
	};

	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
		setCurrentPage(1);
	};

	const filteredAnualreport = Anualreport.filter((item) => {
		const matchesSearchTerm = item.description
			.toLowerCase()
			.includes(searchTerm.toLowerCase());
		const matchesYear = selectedYear ? item.year === selectedYear : true;
		const matchesCategory = selectedCategory
			? item.category === selectedCategory
			: true;
		return matchesSearchTerm && matchesYear && matchesCategory;
	});

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	if (!data || Anualreport.length === 0) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
				<section className="mb-10">
					{/* <h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data?.Title || "Default Title"}
					</h2> */}
					<MarkdownRenderer content={data?.Description} />

					{filteranual && (
						<div className="flex flex-wrap gap-3 justify-center md:justify-start mb-4">
							<button
								className={`w-full md:w-auto px-3 py-2 rounded-md cursor-pointer ${
									selectedCategory === "Annual" ? "bg-[#54A7D6]" : "bg-gray-300"
								} text-white text-center shadow-md`}
								style={{
									fontSize: "1rem",
									maxWidth: "100%",
									height: "50px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								onClick={() => handleCategoryChange("Annual")}
							>
								{language === "en" ? "Annual Report" : "Laporan Tahunan"}
							</button>
							<button
								className={`w-full md:w-auto px-3 py-2 rounded-md cursor-pointer ${
									selectedCategory === "Sustainability"
										? "bg-[#54A7D6]"
										: "bg-gray-300"
								} text-white text-center shadow-md`}
								style={{
									fontSize: "1rem",
									maxWidth: "100%",
									height: "50px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								onClick={() => handleCategoryChange("Sustainability")}
							>
								{language === "en"
									? "Sustainability Report"
									: "Laporan Keberlanjutan"}
							</button>
						</div>
					)}

					{/* Filter Pencarian dan Tahun */}
					<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
						<div className="flex items-center mb-2 sm:mb-0">
							<input
								type="text"
								placeholder="Search document..."
								value={searchTerm}
								onChange={handleSearchChange}
								className="border border-gray-300 rounded-lg px-3 py-1 w-full sm:w-64"
								style={{
									borderColor: "#2E3491",
									fontSize: "14px",
								}}
							/>
						</div>
						<div className="flex items-center">
							<select
								value={selectedYear}
								onChange={handleYearChange}
								className="border border-gray-300 rounded-lg px-3 py-1 w-full sm:w-auto"
								style={{
									borderColor: "#2E3491",
									fontSize: "14px",
								}}
							>
								<option value="">All Year</option>
								{[...new Set(Anualreport.map((item) => item.year))].map(
									(year) => (
										<option
											key={year}
											value={year}
										>
											{year}
										</option>
									)
								)}
							</select>
						</div>
					</div>

					{/* Grid Layout */}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-9">
						{filteredAnualreport.map((item, index) => (
							<div
								key={index}
								className="h-[50vh] bg-white rounded-lg shadow-lg cursor-pointer overflow-hidden relative"
								onClick={() => openModal(item)}
							>
								<img
									src={item.coverImageUrl}
									alt={item.Cover_Image?.Title || "Report Cover"}
									className="w-full h-full object-cover"
								/>
							</div>
						))}
					</div>

					{/* Modal */}
					{isModalOpen && selectedItem && (
						<div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
							<div className="relative bg-white rounded-lg p-6 w-11/12 sm:w-4/5 md:w-3/4 lg:w-1/2 mt-8">
								<button
									className="absolute top-2 right-2 text-blue-600 text-2xl"
									onClick={(e) => {
										e.stopPropagation();
										closeModal();
									}}
								>
									&times;
								</button>
								<div className="flex flex-col md:flex-row justify-between items-center md:items-start">
									{/* Container gambar dengan ukuran tetap */}
									<div
										className="bg-white rounded-lg shadow-lg cursor-pointer overflow-hidden relative mb-4 md:mb-0 mx-auto"
										style={{
											width: "100%", // Full width untuk mode mobile
											maxWidth: "300px", // Batas ukuran gambar
											aspectRatio: "2 / 3", // Memastikan rasio tetap 2:3
										}}
									>
										<img
											src={selectedItem.coverImageUrl}
											alt={selectedItem.description}
											className="w-full h-full object-cover"
										/>
									</div>

									<div className="w-full md:flex-1 text-left md:ml-6">
										<h3 className="text-lg md:text-2xl font-bold mb-4 text-center md:text-left">
											{selectedItem.description}
										</h3>
										<p className="text-sm md:text-base mb-4 text-center md:text-left">
											Update On:{" "}
											{new Date(selectedItem.date).toLocaleDateString()}
										</p>
										<a
											href={selectedItem.downloadLink}
											className="flex items-center justify-center md:justify-start text-blue-500 mt-4 text-sm md:text-lg"
											download
											target="_blank"
										>
											<span className="mr-2">DOWNLOAD</span>
											<img
												src={download}
												alt="Download Icon"
												width="20"
												height="20"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="flex flex-wrap items-center justify-center sm:justify-between my-4 space-x-2">
						<div className="flex items-center">
							<label
								className="mr-2"
								style={{ color: "#2E3491" }}
							>
								Input Page
							</label>
							<input
								type="number"
								value={pageInput}
								onChange={handlePageInputChange}
								className="border border-gray-300 rounded-lg px-2 py-1"
								style={{ borderColor: "#2E3491", width: "60px" }}
							/>
							<button
								onClick={handlePageSearch}
								className="ml-2 px-4 py-1 bg-[#54A7D6] text-white rounded-lg"
							>
								{language === "en" ? "View" : "Lihat"}
							</button>
						</div>

						{/* Pagination Controls */}
						<div className="flex items-center space-x-1 sm:space-x-2 text-blue-900 mt-4 sm:mt-0">
							<button
								onClick={() => handlePageChange(1)}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								&laquo; First
							</button>
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								‹ Prev
							</button>

							{/* Page numbers */}
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(
								(page) => (
									<button
										key={page}
										onClick={() => handlePageChange(page)}
										className={`px-2 py-1 rounded ${
											currentPage === page
												? "text-red-500 font-bold border border-red-500"
												: "text-blue-900"
										}`}
									>
										{page}
									</button>
								)
							)}

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								Next ›
							</button>
							<button
								onClick={() => handlePageChange(totalPages)}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								Last &raquo;
							</button>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default CompanyHistory;
