import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import { Helmet } from "react-helmet";

const News = () => {
	const [newsData, setNewsData] = useState(null);
	const [News, setNews] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [elapsedTime, setElapsedTime] = useState("");
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [pageInput, setPageInput] = useState("");
	const { language } = useLanguage();
	const itemsPerPage = 6;
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	// Moved getRelativeTime to the component scope so it can be used anywhere
	const getRelativeTime = (date) => {
		const now = new Date();
		const targetDate = new Date(date);
		const diffInSeconds = Math.floor((now - targetDate) / 1000);

		const minutes = Math.floor(diffInSeconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) {
			return `${days} hari yang lalu`;
		} else if (hours > 0) {
			return `${hours} jam yang lalu`;
		} else if (minutes > 0) {
			return `${minutes} menit yang lalu`;
		} else {
			return "Baru saja";
		}
	};

	const fetchNewsUpdate = useCallback(async () => {
		const query = `
      query NewsUpdateCollectionPages {
        newsUpdateCollectionPages(
          sort: ["Display_Order:desc", "Date:desc"]
          filters: { 
            Pin_onTop: { eq: true },
            Display_Order: { not: null }, 
            Date: { not: null } 
          }
        ) {
          Title
          Date
          Short_Description
          Slug
          publishedAt
          Images {
            Title
            Image {
              formats
              url
            }
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const news = result.data.newsUpdateCollectionPages[0];
			setNewsData(news);

			if (news?.publishedAt) {
				setElapsedTime(getRelativeTime(news.publishedAt));
			}
		} catch (error) {
			console.error("Error fetching news data:", error.message);
		}
	}, [API_URL, language]);

	const getNewsCount = useCallback(async () => {
		const query = `
      query newsUpdateCollectionPages_connection {
        newsUpdateCollectionPages_connection {
          pageInfo {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			const result = await response.json();
			const totalItems =
				result.data.newsUpdateCollectionPages_connection.pageInfo.total;
			const pageCount = Math.ceil(totalItems / itemsPerPage);
			setTotalPages(pageCount);
		} catch (error) {
			console.error("Error fetching news count:", error.message);
		}
	}, [API_URL, itemsPerPage]);

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
      query {
        generalPage {
          SEO {
            MetaTitle
            MetaDescription
            ShareImage { url }
            MetaSocial {
              Title
              Social_Network
              Description
              Image { url }
            }
            Keywords
            MetaRobots
            StructuredData
            MetaViewPort
            CanonicalURL
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchCardNews = useCallback(
		async (page = 1) => {
			const query = `
        query {
          newsUpdateCollectionPages(
            pagination: { page: ${page}, pageSize: ${itemsPerPage} }
            sort: ["Display_Order:desc", "publishedAt:desc"]
            filters: { Display_Order: { not: null }, publishedAt: { not: null } }
          ) {
            Title
            Short_Description
            publishedAt
            Images {
              Title
              Image {
                formats
                url
              }
            }
            Slug
          }
        }
      `;
			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				const result = await response.json();
				setNews(result.data.newsUpdateCollectionPages);
			} catch (error) {
				console.error("Error fetching report data:", error.message);
			}
		},
		[API_URL, itemsPerPage]
	);

	const fetchNews = useCallback(async () => {
		const query = `
      query {
        newsUpdatePage(locale: "${language}") {
          Title
          SEO {
            Use_GeneralSEO
            SEO {
              MetaTitle
              MetaDescription
              ShareImage { url }
              MetaSocial {
                Title
                Social_Network
                Description
                Image { url }
              }
              Keywords
              MetaRobots
              StructuredData
              MetaViewPort
              CanonicalURL
            }
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.newsUpdatePage;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	useEffect(() => {
		fetchNewsUpdate();
		getNewsCount();
		fetchNews();
	}, [fetchNewsUpdate, getNewsCount, fetchNews]);

	useEffect(() => {
		fetchCardNews(currentPage);
	}, [currentPage, fetchCardNews]);

	if (!data && !newsData && News.length === 0) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const images = newsData?.Images || [];
	const handleFirstPage = () => setCurrentPage(1);
	const handlePreviousPage = () =>
		setCurrentPage((prev) => Math.max(prev - 1, 1));
	const handlePageSelect = (page) => setCurrentPage(page);
	const handleNextPage = () =>
		setCurrentPage((prev) => Math.min(prev + 1, totalPages));
	const handleLastPage = () => setCurrentPage(totalPages);

	const handlePageInputChange = (event) => {
		setPageInput(event.target.value);
	};

	const handlePageSearch = () => {
		const page = Number(pageInput);
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		} else {
			alert(`Please enter a valid page number between 1 and ${totalPages}`);
		}
	};

	const handleNextImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const handlePrevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	return (
		<>
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}
				<section className="mb-16 mt-10 flex flex-col lg:flex-row items-stretch px-4 lg:px-24">
					<div className="relative w-full lg:w-1/2 h-75 md:h-75 lg:h-75 flex-shrink-0">
						<img
							className="w-full h-auto object-cover aspect-[4/3] rounded-lg"
							src={`${API_URL}${
								images[currentImageIndex]?.Image?.formats?.medium?.url ||
								images[currentImageIndex]?.Image?.formats?.Medium?.url ||
								images[currentImageIndex]?.Image?.url
							}`}
							alt={images[currentImageIndex]?.Title || "News"}
						/>

						{images.length > 1 && (
							<>
								<button
									onClick={handlePrevImage}
									className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
								>
									{"<"}
								</button>
								<button
									onClick={handleNextImage}
									className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
								>
									{">"}
								</button>
							</>
						)}
					</div>

					<div className="relative w-full lg:w-1/2 mt-2 lg:mt-0 text-left lg:pl-6 flex flex-col justify-between font-helvetica">
						<div className="flex-grow">
							<h3
								className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl font-bold mb-4"
								style={{ color: "#2E3491" }}
							>
								{newsData?.Title}
							</h3>
							<p className="text-red-500 lg:text-xl md:text-xl text-base mb-2">
								{elapsedTime}
							</p>
							<p className="text-gray-600 lg:text-xl md:text-xl text-base mb-2 text-justify">
								{newsData?.Short_Description}
							</p>
						</div>
						<button
							className="bg-[#54A7D6] text-white px-3 py-1.5 rounded hover:bg-blue-600 transition duration-300 lg:text-2xl md:text-xl text-base"
							onClick={() =>
								(window.location.href = `/about/news/detail/${newsData.Slug}`)
							}
						>
							{language === "en" ? "Read More" : "Baca Selengkapnya"}
						</button>
					</div>
				</section>

				<section className="mb-12 px-4 lg:px-24">
					<h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold mb-8 text-left sm:mb-10"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data?.Title}
					</h2>
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
						{News.map((item, index) => (
							<a
								key={index}
								href={`/about/news/detail/${item.Slug}`}
								rel="noopener noreferrer"
								className="flex flex-col bg-white bg-opacity-75 rounded-lg shadow-lg overflow-hidden h-full"
							>
								<img
									className="w-full h-auto object-cover aspect-[4/3] rounded-lg"
									src={`${API_URL}${
										item.Images[0]?.Image?.formats?.small?.url ||
										item.Images[0]?.Image?.formats?.Small?.url ||
										item.Images[0]?.Image?.url
									}`}
									alt="News"
								/>

								<div className="flex flex-col justify-between flex-grow p-4 text-left">
									<div>
										<p className="text-gray-500 text-sm mb-2">
											{new Date(item.publishedAt).toLocaleDateString("id-ID", {
												year: "numeric",
												month: "long",
												day: "2-digit",
											})}
											, ({getRelativeTime(item.publishedAt)})
										</p>
										<h3 className="lg:text-2xl md:text-2xl sm:text-large font-semibold text-gray-800 mb-2">
											{item?.Title}
										</h3>
										<p className="text-gray-600 mb-4 lg:text-large md:text-large text-base text-justify">
											{item?.Short_Description}
										</p>
									</div>
									<button className="bg-[#54A7D6] text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-auto">
										{language === "en" ? "Read More" : "Baca Selengkapnya"}
									</button>
								</div>
							</a>
						))}
					</div>

					{/* Pagination */}
					<div className="flex flex-col sm:flex-row items-center justify-between my-4 space-y-2 sm:space-y-0 sm:space-x-4">
						{/* Input Page */}
						<div className="flex items-center">
							<label
								className="mr-2"
								style={{ color: "#2E3491" }}
							>
								Input Page
							</label>
							<input
								type="number"
								value={pageInput}
								onChange={handlePageInputChange}
								className="border border-gray-300 rounded-lg px-2 py-1"
								style={{ borderColor: "#2E3491", width: "60px" }}
							/>
							<button
								onClick={handlePageSearch}
								className="ml-2 px-4 py-1 bg-[#54A7D6] text-white rounded-lg hover:bg-blue-700"
							>
								{language === "en" ? "View" : "Lihat"}
							</button>
						</div>

						{/* Pagination Controls */}
						<div className="flex flex-wrap justify-center sm:justify-end space-x-2 text-blue-900">
							<button
								onClick={handleFirstPage}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1 ? "text-gray-400" : "text-blue-900"
								}`}
							>
								&laquo; First
							</button>
							<button
								onClick={handlePreviousPage}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1 ? "text-gray-400" : "text-blue-900"
								}`}
							>
								&lsaquo; Prev
							</button>

							{/* Page Numbers */}
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(
								(page) => (
									<button
										key={page}
										onClick={() => handlePageSelect(page)}
										className={`px-2 py-1 ${
											currentPage === page
												? "text-red-500 font-bold"
												: "text-blue-900"
										}`}
									>
										{page}
									</button>
								)
							)}

							<button
								onClick={handleNextPage}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages ? "text-gray-400" : "text-blue-900"
								}`}
							>
								Next &rsaquo;
							</button>
							<button
								onClick={handleLastPage}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages ? "text-gray-400" : "text-blue-900"
								}`}
							>
								Last &raquo;
							</button>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default News;
