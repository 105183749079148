import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import { Helmet } from "react-helmet";

const CompanyHistory = () => {
	const { language } = useLanguage();
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [selectedYear, setSelectedYear] = useState(null);
	const [data, setData] = useState(null);
	const [timelineData, setTimelineData] = useState([]);
	const [seoData, setSEO] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						Keywords
						MetaRobots
						CanonicalURL
						MetaViewPort
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSEO(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchHistoryContent = useCallback(async () => {
		const query = `
            query {
                historyCollectionPages(locale: "${language}") {
                    Year
                    Description
                    Images { url formats }
                    Display_Order
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});

			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();

			const sortedData = result.data.historyCollectionPages.sort(
				(a, b) => b.Display_Order - a.Display_Order
			);
			setTimelineData(sortedData);
		} catch (error) {
			console.error("Error fetching history content:", error.message);
		}
	}, [API_URL, language]);

	const fetchHistoryData = useCallback(async () => {
		const query = `
            query {
                historyPage(locale: "${language}") {
                    Title
                    Paragraph_Content
                    SEO {
                        Use_GeneralSEO
                        SEO {
                            MetaTitle
                            MetaDescription
                            ShareImage { url }
                            Keywords
                            CanonicalURL
                            MetaViewPort
                        }
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setData(result.data.historyPage);

			if (result.data.historyPage.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSEO(result.data.historyPage.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching history data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	useEffect(() => {
		fetchHistoryContent();
		fetchHistoryData();
	}, [fetchHistoryContent, fetchHistoryData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={seoData?.MetaDescription || "Default description"}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
		</Helmet>
	);

	const handleNextImage = (images) => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === images.length - 1 ? 0 : prevIndex + 1
		);
	};

	const handlePreviousImage = (images) => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};

	const toggleYear = (year) => {
		setSelectedYear(selectedYear === year ? null : year);
	};

	return (
		<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
				<section className="mb-10">
					{/* <h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data?.Title || "Our Story"}
					</h2> */}
					<p className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica text-left">
						{data?.Paragraph_Content ||
							"Company history description goes here."}
					</p>
				</section>

				<div className="container mx-auto p-8 relative">
					<div
						className="absolute top-0 bottom-0 w-1 bg-blue-800"
						style={{ left: "2.4rem" }}
					></div>

					{timelineData.map((item, index) => (
						<section
							className="mb-12 relative pl-12"
							key={index}
						>
							<div className="flex flex-col items-start">
								<div
									className="mb-4 cursor-pointer relative flex items-center"
									onClick={() => toggleYear(item.Year)}
								>
									<h2
										className="text-4xl font-bold relative"
										style={{ color: "#2E3491", paddingLeft: "20px" }}
									>
										<span className="absolute -left-12 top-2 w-4 h-4 rounded-full bg-blue-800"></span>
										{item.Year}
									</h2>
									<span className="ml-2">
										{selectedYear === item.Year ? (
											<span>&#9650; {/* Panah ke atas */}</span>
										) : (
											<span>&#9660; {/* Panah ke bawah */}</span>
										)}
									</span>
								</div>

								{selectedYear === item.Year && item.Images ? (
									<div className="hidden md:flex flex-row w-full items-start">
										<div className="w-full relative md:w-1/3 lg:w-1/3">
											<img
												src={`${API_URL}${
													item.Images[currentImageIndex]?.thumbnail?.url ||
													item.Images[currentImageIndex]?.url
												}`}
												alt={`Timeline ${item.Year}`}
												className="w-full bg-cover bg-center rounded-lg shadow-lg"
												style={{
													maxWidth: "400px",
													height: "200px",
													objectFit: "cover",
												}}
											/>
											<button
												onClick={() => handlePreviousImage(item.Images)}
												className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
												style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
											>
												{"<"}
											</button>
											<button
												onClick={() => handleNextImage(item.Images)}
												className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
												style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
											>
												{">"}
											</button>
										</div>
										<div className="w-full md:ml-4 lg:mr-80 md:w-2/3 lg:w-2/3 xl:w-2/3">
											<p className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica text-left">
												{item.Description}
											</p>
										</div>
									</div>
								) : (
									<div className="ml-6">
										<p className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica text-left">
											{item.Description}
										</p>
									</div>
								)}
							</div>
						</section>
					))}
				</div>
			</div>
		</div>
	);
};

export default CompanyHistory;
