import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import remarkGfm from "remark-gfm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const CompanyGrups = () => {
	const [activeIndex, setActiveIndex] = useState(null);
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						MetaSocial {
							Title
							Social_Network
							Description
							Image { url }
						}
						Keywords
						MetaRobots
						StructuredData
						MetaViewPort
						CanonicalURL
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchCompanyGroups = useCallback(async () => {
		const query = `
      query {
        organizationGroupStructure(locale: "${language}") {
          Title
          Structure_Image { Title Image { url formats } }
          Company_GroupMembers {
            Name
            Description
            Image { url formats }
            Company_Url
          }
          SEO {
            Use_GeneralSEO
            SEO {
              MetaTitle
              MetaDescription
              ShareImage { url }
              Keywords
            }
          }
        }
      }
    `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const organizationGroupStructureData =
				result.data.organizationGroupStructure;
			setData(organizationGroupStructureData);
			if (organizationGroupStructureData.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(organizationGroupStructureData.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching CompanyGrup data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchCompanyGroups();
	}, [fetchCompanyGroups]);

	const toggleAccordion = (index) => {
		setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
				<section className="mb-10">
					{/* <h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data.Title}
					</h2> */}
					<img
						src={
							data.Structure_Image?.Image?.url
								? `${API_URL}${
										data.Structure_Image.Image.large?.url ||
										data.Structure_Image.Image.url
								  }`
								: ""
						}
						alt={data.Structure_Image.Image.Title}
						className="w-full h-auto sm:max-w-lg sm:mx-auto"
						style={{ maxWidth: "80%", height: "auto" }}
					/>
				</section>

				<section className="border border-gray-300 rounded-md">
					{data.Company_GroupMembers?.map((company, index) => (
						<div
							key={index}
							className="border-b border-gray-300 cursor-pointer"
							onClick={() => toggleAccordion(index)}
							style={{
								borderBottomWidth: "1px",
								borderTopWidth: "2px",
								borderRightWidth: "2px",
								borderLeftWidth: "2px",
								borderColor: "#2E3491",
							}}
						>
							<div className="flex justify-between p-4 text-large md:text-2xl lg:text-2xl font-semibold text-blue-900 font-helvetica">
								{company.Name}
								<span>{activeIndex === index ? "▲" : "▼"}</span>
							</div>
							{activeIndex === index && (
								<div
									className="p-4 flex flex-col md:flex-row items-start border-t-2 border-blue-900"
									onClick={(e) => e.stopPropagation()} // Mencegah event bubbling
								>
									<Swiper
										spaceBetween={10}
										centeredSlides={true}
										autoplay={{
											delay: 30000,
											disableOnInteraction: false,
										}}
										navigation={true}
										modules={[Autoplay, Navigation]}
										className="w-full md:w-1/3 h-auto mr-0 md:mr-4 mb-4 md:mb-0 rounded-lg"
										onClick={(e) => {
											if (e?.stopPropagation) {
												e.stopPropagation();
											} else if (e?.nativeEvent?.stopPropagation) {
												e.nativeEvent.stopPropagation();
											}
										}}
									>
										{company.Image?.map((img, imgIndex) => (
											<SwiperSlide key={imgIndex}>
												<div className="relative w-full">
													<img
														src={`${API_URL}${img.medium?.url || img.url}`}
														alt={`${company.Name}`}
														className="w-full h-[250px] sm:h-[300px] md:h-[250px] lg:h-[400px] object-cover rounded-lg"
													/>
												</div>
											</SwiperSlide>
										))}
									</Swiper>

									<div className="w-full md:w-2/3 text-left md:ml-4 lg:ml-8">
										<MarkdownRenderer content={company.Description} />
										<a
											href={company.Company_Url}
											target="_blank"
											rel="noopener noreferrer"
											className="bg-[#54A7D6] text-white px-4 py-2 rounded hover:bg-blue-600 transition font-helvetica"
										>
											Link website ↗
										</a>
									</div>
								</div>
							)}
						</div>
					))}
				</section>
			</div>
		</div>
	);
};

export default CompanyGrups;
