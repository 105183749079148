import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import DownloadIcon from "../../../asset/Vector.png";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const ShareholdingStructure = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [governanceItems, setGovernanceItems] = useState([]);
	const [activeIndex, setActiveIndex] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						MetaSocial {
							Title
							Social_Network
							Description
							Image { url }
						}
						Keywords
						MetaRobots
						StructuredData
						MetaViewPort
						CanonicalURL
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchGovernance = useCallback(async () => {
		const query = `
			query {
				governancePage(locale: "${language}") {
					Title
					SEO {
						Use_GeneralSEO
						SEO {
							MetaTitle
							MetaDescription
							ShareImage { url }
							MetaSocial {
								Title
								Social_Network
								Description
								Image { url }
							}
							Keywords
							MetaRobots
							StructuredData
							MetaViewPort
							CanonicalURL
						}
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.governancePage;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const fetchGovernanceData = useCallback(async () => {
		const query = `
        query {
          sustainabilityGovernanceContents(locale: "${language}") {
            Display_Order
            Title
            Description
            Programs {
              Title
              Description
              Display_Order
              File {
                Title
                File {
                  url
                }
              }
            }
          }
        }
      `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setGovernanceItems(result.data.sustainabilityGovernanceContents);
		} catch (error) {
			console.error("Error fetching governance items:", error.message);
		}
	}, [API_URL, language]);

	useEffect(() => {
		fetchGovernance();
		fetchGovernanceData();
	}, [fetchGovernance, fetchGovernanceData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	if (!data || governanceItems.length === 0) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<>
			{/* Layout Page */}
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}
				<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
					<section className="mb-10">
						{/* <h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{data.Title}
						</h2> */}
					</section>
					<section className="w-full">
						<div className="border border-gray-300 rounded-md">
							{governanceItems.map((item, index) => (
								<div
									key={index}
									className="border-b border-gray-300 cursor-pointer"
									onClick={() => toggleAccordion(index)}
									style={{
										borderBottomWidth: "1px",
										borderTopWidth: "2px",
										borderRightWidth: "2px",
										borderLeftWidth: "2px",
										borderColor: "#2E3491",
									}}
								>
									<div className="flex justify-between p-4 text-large md:text-xl lg:text-xl font-semibold text-blue-900">
										{item.Title}
										<span>{activeIndex === index ? "▲" : "▼"}</span>
									</div>

									{activeIndex === index && (
										<div className="p-4 border-t-2 border-blue-900">
											<div className="text-left mb-4">
												<MarkdownRenderer content={item.Description} />

												{item.Programs && item.Programs.length > 0 && (
													<div>
														{item.Programs.map((program, programIndex) => (
															<div
																key={programIndex}
																className="mb-4"
															>
																<h4 className="text-blue-900 mb-4 font-semibold text-large md:text-2xl lg:text-2xl">
																	{program.Title}
																</h4>
																<MarkdownRenderer
																	content={program.Description}
																/>

																{program.File && program.File.length > 0 && (
																	<div>
																		{program.File.map((file, fileIndex) => (
																			<a
																				key={fileIndex}
																				href={`${API_URL}${file.File.url}`}
																				rel="noopener noreferrer"
																				target="_blank"
																				className="lg:text-xl md:text-large text-base flex flex-col md:flex-row items-center md:items-center justify-between border-2 border-blue-900 px-4 py-2 text-blue-900 font-medium rounded mb-4 mt-4"
																				style={{
																					width: "100%",
																					maxWidth: "10000px",
																				}}
																			>
																				<span className="mb-2 md:mb-0">
																					{file.Title}
																				</span>
																				<img
																					src={DownloadIcon} // Ganti dengan URL atau path gambar yang ingin digunakan
																					alt="Download"
																					className="w-6 h-6 md:w-8 md:h-8 inline-block md:ml-4" // Sesuaikan ukuran gambar
																				/>
																			</a>
																		))}
																	</div>
																)}
															</div>
														))}
													</div>
												)}
											</div>
										</div>
									)}
								</div>
							))}
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default ShareholdingStructure;
