import React, { useState, useEffect, useCallback } from "react";
import DownloadIcon from "../../../asset/Vector.png";
import { useLanguage } from "../../../context/LanguageContext";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);
const CompanyHistory = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedYear, setSelectedYear] = useState("");
	const [Financial, setFinancial] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const itemsPerPage = 10;
	const { language } = useLanguage();
	const [seoData, setSeoData] = useState(null);
	const [data, setData] = useState(null);
	const [pageInput, setPageInput] = useState(""); // State untuk menyimpan input halaman

	const API_URL = process.env.REACT_APP_API_URL;

	const getFinancialCount = useCallback(async () => {
		const query = `
			query FinancialReports_connection {
				financialReports_connection {
					pageInfo {
						total
						page
						pageSize
						pageCount
					}
				}
			}
		`;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();

			const totalItems = result.data.financialReports_connection.pageInfo.total;
			const pageCount = Math.ceil(totalItems / itemsPerPage);
			setTotalPages(pageCount);
		} catch (error) {
			console.error("Error fetching Financial count:", error.message);
		}
	}, [API_URL]);

	const fetcFinancial = useCallback(
		async (page = 1) => {
			const query = `
            query {
                financialReports(
                    pagination: { page: ${page}, pageSize: ${itemsPerPage} }
                    locale: "${language}"
                    sort: ["Display_Order:desc", "Date:desc"]
                ) {
                    Title
                    Date
                    Display_Order
                    Document {
                        Title
                        File {
                            url
                        }
                    }
                }
            }
        `;

			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				if (!response.ok)
					throw new Error(`HTTP error! status: ${response.status}`);
				const result = await response.json();

				const formattedFinancial = result.data.financialReports
					.map((item) => ({
						year: new Date(item.Date).getFullYear().toString(),
						description: item.Title,
						date: item.Date,
						displayOrder: item.Display_Order,
						downloadLink: item.Document?.File?.url
							? `${API_URL}${item.Document.File.url}`
							: "#",
					}))
					.sort((a, b) => {
						if (b.displayOrder === a.displayOrder) {
							return new Date(b.date) - new Date(a.date);
						}
						return b.displayOrder - a.displayOrder;
					}); // Frontend sort as a backup

				setFinancial(formattedFinancial);
			} catch (error) {
				console.error("Error fetching Financial data:", error.message);
			}
		},
		[API_URL, language, itemsPerPage]
	);

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						MetaSocial {
							Title
							Social_Network
							Description
							Image { url }
						}
						Keywords
						MetaRobots
						StructuredData
						MetaViewPort
						CanonicalURL
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchFinanceData = useCallback(async () => {
		const query = `
			query {
				financialReportPage(locale: "${language}") {
					Title
					Description
					SEO {
						Use_GeneralSEO
						SEO {
							MetaTitle
							MetaDescription
							ShareImage { url }
							MetaSocial {
								Title
								Social_Network
								Description
								Image { url }
							}
							Keywords
							MetaRobots
							StructuredData
							MetaViewPort
							CanonicalURL
						}
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const Financial = result.data.financialReportPage;
			setData(Financial);

			if (Financial.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(Financial.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	useEffect(() => {
		getFinancialCount();
		fetchFinanceData();
	}, [getFinancialCount, fetchFinanceData]);

	useEffect(() => {
		fetcFinancial(currentPage);
	}, [currentPage, fetcFinancial]);

	const handlePageInputChange = (event) => {
		setPageInput(event.target.value);
	};

	const handlePageSearch = () => {
		const pageNumber = parseInt(pageInput, 10);
		if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
			setCurrentPage(pageNumber);
			setPageInput(""); // Reset input setelah berpindah halaman
		} else {
			alert(`Please enter a page number between 1 and ${totalPages}`);
		}
	};

	const handlePageChange = (page) => {
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		}
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
		setCurrentPage(1);
	};

	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
		setCurrentPage(1);
	};

	const filteredFinancial = Financial.filter((item) => {
		const matchesSearchTerm = item.description
			.toLowerCase()
			.includes(searchTerm.toLowerCase());
		const matchesYear = selectedYear ? item.year === selectedYear : true;
		return matchesSearchTerm && matchesYear;
	});

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	if (!data || Financial.length === 0) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
				<section className="mb-10">
					{/* <h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
						style={{
							color: "#2E3491",
							borderLeft: "8px solid red",
							paddingLeft: "10px",
						}}
					>
						{data?.Title}
					</h2> */}
					<MarkdownRenderer content={data?.Description} />

					{/* Filter Pencarian dan Tahun */}
					<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
						<div className="flex items-center mb-2 sm:mb-0">
							<input
								type="text"
								placeholder="Search document..."
								value={searchTerm}
								onChange={handleSearchChange}
								className="border border-gray-300 rounded-lg px-3 py-1 w-full sm:w-64"
								style={{
									borderColor: "#2E3491",
									fontSize: "14px",
								}}
							/>
						</div>
						<div className="flex items-center">
							<select
								value={selectedYear}
								onChange={handleYearChange}
								className="border border-gray-300 rounded-lg px-3 py-1 w-full sm:w-auto"
								style={{
									borderColor: "#2E3491",
									fontSize: "14px",
								}}
							>
								<option value="">All Year</option>
								{[...new Set(Financial.map((item) => item.year))].map(
									(year) => (
										<option
											key={year}
											value={year}
										>
											{year}
										</option>
									)
								)}
							</select>
						</div>
					</div>

					{/* Tabel Data */}
					<div className="overflow-x-auto">
						<table
							className="min-w-full bg-white border border-gray-300 rounded-md"
							style={{
								backgroundColor: "rgba(255, 255, 255, 0.01)",
								fontSize: "16px",
								borderWidth: "2px",
								borderColor: "#2E3491",
							}}
						>
							<thead>
								<tr>
									<th
										className="px-4 md:px-6 py-2 md:py-4 text-left border-b border-[#2E3491] text-white lg:text-xl md:text-large text-base"
										style={{
											backgroundColor: "#54A7D6",
											borderBottom: "2px solid #2E3491",
										}}
									>
										{language === "en" ? "Year" : "Tahun"}
									</th>
									<th
										className="px-4 md:px-6 py-2 md:py-4 text-left border-b border-[#2E3491] text-white lg:text-xl md:text-large text-base"
										style={{
											backgroundColor: "#54A7D6",
											borderBottom: "2px solid #2E3491",
										}}
									>
										{language === "en" ? "Description" : "Deskripsi"}
									</th>
									<th
										className="px-4 md:px-6 py-2 md:py-4 text-left border-b border-[#2E3491] text-white lg:text-xl md:text-large text-base"
										style={{
											backgroundColor: "#54A7D6",
											borderBottom: "2px solid #2E3491",
											textAlign: "center", // Ensures the text is centered in the header
										}}
									>
										{language === "en" ? "Download" : "Unduh"}
									</th>
								</tr>
							</thead>
							<tbody>
								{filteredFinancial.map((item, index) => (
									<tr
										key={index}
										className="border-b"
										style={{ borderBottom: "2px solid #2E3491" }}
									>
										<td className="px-4 md:px-6 py-2 md:py-4 text-left lg:text-xl md:text-large text-base">
											{item.year}
										</td>
										<td className="px-4 md:px-6 py-2 md:py-4 text-left lg:text-xl md:text-large text-base	">
											{item.description}
										</td>
										<td className="px-4 md:px-6 py-2 md:py-4 text-center">
											<a
												href={item.downloadLink}
												rel="noopener noreferrer"
												target="_blank"
												download
												className="w-6 h-6 md:w-8 md:h-8 inline-block"
											>
												<img
													src={DownloadIcon}
													alt="Download"
													className="mx-auto"
													style={{ width: "20px", height: "20px" }}
												/>
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{/* Pagination */}
					{/* Input for Page with Search Button */}
					<div className="flex flex-wrap items-center justify-center sm:justify-between my-4 space-x-2">
						<div className="flex items-center">
							<label
								className="mr-2"
								style={{ color: "#2E3491" }}
							>
								Input Page
							</label>
							<input
								type="number"
								value={pageInput}
								onChange={handlePageInputChange}
								className="border border-gray-300 rounded-lg px-2 py-1"
								style={{ borderColor: "#2E3491", width: "60px" }}
							/>
							<button
								onClick={handlePageSearch}
								className="ml-2 px-4 py-1 bg-[#54A7D6] text-white rounded-lg"
							>
								{language === "en" ? "View" : "Lihat"}
							</button>
						</div>

						{/* Pagination Controls */}
						<div className="flex items-center space-x-1 sm:space-x-2 text-blue-900 mt-4 sm:mt-0">
							<button
								onClick={() => handlePageChange(1)}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								&laquo; First
							</button>
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								‹ Prev
							</button>

							{/* Page numbers */}
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(
								(page) => (
									<button
										key={page}
										onClick={() => handlePageChange(page)}
										className={`px-2 py-1 rounded ${
											currentPage === page
												? "text-red-500 font-bold border border-red-500"
												: "text-blue-900"
										}`}
									>
										{page}
									</button>
								)
							)}

							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								Next ›
							</button>
							<button
								onClick={() => handlePageChange(totalPages)}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages
										? "text-gray-400 cursor-not-allowed"
										: "text-blue-900"
								}`}
							>
								Last &raquo;
							</button>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default CompanyHistory;
