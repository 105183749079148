// components/NewsAndPressRelease.js
import React, { useState, useEffect, useCallback } from "react"; // Import useEffect untuk memanggil fetch data saat komponen mount
import bgnews from "../../asset/Bg-News.png";
import { useLanguage } from "../../context/LanguageContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-2xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

function NewsAndPressRelease() {
	const [newsItems, setNewsItems] = useState([]);
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const { language } = useLanguage();
	const [data, setData] = useState(null);

	const itemsPerPage = 6;
	const API_URL = process.env.REACT_APP_API_URL; // Pastikan API_URL diatur di .env
	const API_TOKEN = process.env.REACT_APP_API_TOKEN;

	const fetchCardNews = useCallback(
		async (page = 1) => {
			const query = `
            query {
                newsUpdateCollectionPages(
				pagination: { page: ${page}, pageSize: ${itemsPerPage} }
				sort: ["Display_Order:desc", "publishedAt:desc"]
				filters: { 
                    Pin_toHome: { eq: true },
                    Display_Order: { not: null }, 
                    publishedAt: { not: null } 
                }){
                    Title
                    Article
                    publishedAt
                    Images {
                        Title
                        Image {
                            url
							formats
                        }
                    }
                    Display_Order
                    Slug
                }
            }
        `;

			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				const result = await response.json();

				if (result.data && result.data.newsUpdateCollectionPages) {
					const validData = result.data.newsUpdateCollectionPages.filter(
						(item) => item && item.Title && item.Images
					);
					setNewsItems(validData);
				} else {
					console.error(
						"No data received or incorrect data structure:",
						result
					);
				}
			} catch (error) {
				console.error("Error fetching report data:", error.message);
			}
		},
		[API_URL, itemsPerPage, API_TOKEN]
	);

	const fetchHome = useCallback(async () => {
		const query = `
            query {
                home(locale: "${language}") {
                    News_Title
					News_SubTitle
                }
            }
        `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchedData = result.data.home;
			setData(fetchedData);
		} catch (error) {
			console.error("Error fetching home data:", error.message);
		}
	}, [API_URL, language]);

	useEffect(() => {
		fetchCardNews();
		fetchHome(); // Fetch data saat komponen di-mount
	}, [fetchCardNews, fetchHome]);

	// Function to open the popup
	const handlePopupOpen = (item) => {
		setCurrentItem(item);
		setCurrentImageIndex(0);
		setPopupOpen(true);
	};

	// Function to close the popup
	const handlePopupClose = () => {
		setPopupOpen(false);
		setCurrentItem(null);
	};

	// Function to go to the next image
	const handleNextImage = () => {
		if (currentItem && currentImageIndex < currentItem.Images.length - 1) {
			setCurrentImageIndex(currentImageIndex + 1);
		}
	};

	// Function to go to the previous image
	const handlePrevImage = () => {
		if (currentItem && currentImageIndex > 0) {
			setCurrentImageIndex(currentImageIndex - 1);
		}
	};

	return (
		<div className="font-helvetica">
			{/* Desktop View */}
			<section
				className="pt-10 pb-20 relative bg-cover bg-center hidden lg:flex z-20"
				style={{ backgroundImage: `url(${bgnews})` }}
			>
				<div className="container mx-auto px-10 md:px-20">
					<div className="flex items-center justify-between mb-5">
						<h2 className="text-3xl font-bold text-left text-[#2E3491]">
							{data?.News_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-5">
						<h3 className="text-2xl font-semibold text-left text-[#2E3491]">
							{data?.News_SubTitle}
						</h3>
						<a
							href="/about/news"
							className="flex items-center bg-[#1C24A3] text-white text-sm px-4 py-2 rounded-lg space-x-2"
						>
							<span>{language === "en" ? "VIEW ALL" : "LIHAT SEMUA"}</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5l7 7-7 7"
								/>
							</svg>
						</a>
					</div>

					<div className="bg-gradient-to-r from-[#076599] to-[#49ABE1] bg-opacity-80 rounded-lg p-8 shadow-md">
						<div className="flex flex-wrap justify-center md:justify-between">
							{newsItems.map((item, index) => (
								<div
									key={index}
									className="w-full md:w-1/2 mb-20 pr-8 md:pr-12" // Increased padding-right
								>
									<h4 className="text-lg font-semibold text-white text-left">
										{item.Title}
									</h4>
									<div className="flex justify-between items-center mt-2">
										<div className="flex items-center space-x-2">
											<p className="text-white text-left">
												{new Date(item.publishedAt).toLocaleDateString(
													"id-ID",
													{
														year: "numeric",
														month: "long",
														day: "2-digit",
													}
												)}
												,{" "}
												{new Date(item.publishedAt).toLocaleTimeString(
													"id-ID",
													{
														hour: "2-digit",
														minute: "2-digit",
														second: "2-digit",
														hour12: false,
													}
												)}
											</p>
											<a
												href={`/${item.Slug}`}
												className="bg-red-500 text-white p-2 rounded-full flex items-center justify-center"
												onClick={(e) => {
													e.preventDefault();
													handlePopupOpen(item);
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-4 w-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M9 5l7 7-7 7"
													/>
												</svg>
											</a>
										</div>
										<a
											href={`/about/news/detail/${item.Slug}`}
											className="border border-white text-white text-sm px-6 py-2 rounded-lg w-[200px] h-[40px] flex items-center justify-center"
										>
											{language === "en" ? "Read More" : "Baca Selengkapnya"}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* Tablet View */}
			<section
				className="pt-2 pb-1 relative bg-cover bg-center hidden md:flex lg:hidden z-20 mb-8 mt-2"
				style={{ backgroundImage: `url(${bgnews})`, height: "650px" }}
			>
				<div className="container mx-auto px-4 md:px-10">
					<div className="flex items-center justify-between mb-2">
						<h2 className="text-3xl font-bold text-left text-[#2E3491]">
							{data?.News_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-4">
						<h3 className="text-2xl font-semibold text-left text-[#2E3491]">
							{data?.News_SubTitle}
						</h3>
						<a
							href="/about/news"
							className="flex items-center bg-[#1C24A3] text-white text-sm px-3 py-2 rounded-lg space-x-2"
						>
							<span>{language === "en" ? "VIEW ALL" : "LIHAT SEMUA"}</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M9 5l7 7-7 7"
								/>
							</svg>
						</a>
					</div>

					<div className="bg-gradient-to-r from-[#076599] to-[#49ABE1] bg-opacity-80 rounded-lg p-6 shadow-md">
						<div className="flex flex-wrap justify-center md:justify-between">
							{newsItems.map((item, index) => (
								<div
									key={index}
									className="w-full md:w-1/2 mb-12 pr-6 md:pr-8" // Increased padding-right and margin-bottom
								>
									<h4 className="text-lg font-semibold text-white text-left">
										{item.Title}
									</h4>
									<div className="flex justify-between items-center mt-1">
										<div className="flex items-center space-x-2">
											<p className="text-white text-left">
												{new Date(item.publishedAt).toLocaleDateString(
													"id-ID",
													{
														year: "numeric",
														month: "long",
														day: "2-digit",
													}
												)}
												,{" "}
												{new Date(item.publishedAt).toLocaleTimeString(
													"id-ID",
													{
														hour: "2-digit",
														minute: "2-digit",
														second: "2-digit",
														hour12: false,
													}
												)}
											</p>
											<a
												href={`/${item.Slug}`}
												className="bg-red-500 text-white p-2 rounded-full flex items-center justify-center"
												onClick={(e) => {
													e.preventDefault();
													handlePopupOpen(item);
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-4 w-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M9 5l7 7-7 7"
													/>
												</svg>
											</a>
										</div>
										<div className="ml-2">
											<a
												href={`/about/news/detail/${item.Slug}`}
												className="border border-white text-white text-sm px-5 py-2 rounded-lg flex items-center justify-center"
											>
												{language === "en" ? "Read More" : "Baca Selengkapnya"}
											</a>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* Mobile View */}
			<section
				className="pt-2 pb-1 relative bg-cover bg-center block md:hidden overflow-y-auto z-20"
				style={{
					backgroundImage: `url(${bgnews})`,
					minHeight: "500px",
					maxHeight: "calc(100vh - 100px)",
				}}
			>
				<div className="container mx-auto px-2">
					<div className="flex items-center justify-between mb-1">
						<h2 className="text-2xl font-bold text-left text-[#2E3491]">
							{data?.News_Title}
						</h2>
					</div>

					<div className="flex items-center justify-between mb-2">
						<h3 className="text-lg font-semibold text-left text-[#2E3491]">
							{data?.News_SubTitle}
						</h3>
					</div>

					<div className="bg-gradient-to-r from-[#076599] to-[#49ABE1] bg-opacity-80 rounded-lg p-4 shadow-md">
						<div className="flex flex-wrap justify-center">
							{newsItems.slice(0, 6).map((item, index) => (
								<div
									key={index}
									className="w-full mb-6 pr-1"
								>
									<h4 className="text-base font-semibold text-white text-left">
										{item.Title}
									</h4>
									<div className="flex justify-between items-center mt-1">
										<div className="flex items-center space-x-1">
											<p className="text-sm text-white text-left">
												{new Date(item.publishedAt).toLocaleDateString(
													"id-ID",
													{
														year: "numeric",
														month: "long",
														day: "2-digit",
													}
												)}
												,{" "}
												{new Date(item.publishedAt).toLocaleTimeString(
													"id-ID",
													{
														hour: "2-digit",
														minute: "2-digit",
														second: "2-digit",
														hour12: false,
													}
												)}
											</p>
											<a
												href={`/${item.Slug}`}
												className="bg-red-500 text-white p-1 rounded-full flex items-center justify-center"
												onClick={(e) => {
													e.preventDefault();
													handlePopupOpen(item);
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-3 w-3"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M9 5l7 7-7 7"
													/>
												</svg>
											</a>
										</div>
										<div className="ml-2">
											<a
												href={`/about/news/detail/${item.Slug}`}
												className="border border-white text-white text-xs px-4 py-1 rounded-lg w-[150px] h-[30px] flex items-center justify-center"
											>
												{language === "en" ? "Read More" : "Baca Selengkapnya"}
											</a>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<a
						href="/about/news"
						className="flex items-center justify-center bg-[#1C24A3] text-white text-sm px-4 py-2 rounded-lg space-x-2 mt-3"
					>
						<span>{language === "en" ? "VIEW ALL" : "LIHAT SEMUA"}</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-4 w-4"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M9 5l7 7-7 7"
							/>
						</svg>
					</a>
				</div>
			</section>

			{/* Popup Modal */}
			{isPopupOpen && currentItem && (
				<div className="fixed inset-0 top-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
					<div className="bg-white p-4 md:p-6 rounded-lg w-full max-w-[90%] md:max-w-3xl lg:max-w-4xl h-auto max-h-[80vh] overflow-y-auto shadow-lg relative">
						<button
							className="absolute top-2 right-2 text-black hover:text-gray-700"
							onClick={handlePopupClose}
						>
							X
						</button>

						<div className="relative">
							{currentItem.Images && currentItem.Images[currentImageIndex] ? (
								<img
									src={`${API_URL}${currentItem.Images[currentImageIndex].Image.url}`}
									alt={currentItem.Images[currentImageIndex].Title || "Image"}
									className="w-full h-auto object-contain mb-4 rounded text-justify"
								/>
							) : (
								<p className="text-center text-gray-500">
									Gambar tidak tersedia
								</p>
							)}

							{currentImageIndex > 0 && (
								<button
									onClick={handlePrevImage}
									className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-3xl"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										className="w-8 h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								</button>
							)}
							{currentImageIndex < currentItem.Images.length - 1 && (
								<button
									onClick={handleNextImage}
									className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-3xl"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										className="w-8 h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M9 5l7 7-7 7"
										/>
									</svg>
								</button>
							)}
						</div>

						<h2 className="text-xl font-bold mb-3 text-justify">
							{currentItem.Title}
						</h2>
						<MarkdownRenderer content={currentItem.Article}></MarkdownRenderer>
					</div>
				</div>
			)}
		</div>
	);
}

export default NewsAndPressRelease;
