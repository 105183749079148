import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for language
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
	const [language, setLanguage] = useState(() => {
		return localStorage.getItem("language") || "id"; // Default to Indonesian
	});

	useEffect(() => {
		localStorage.setItem("language", language);
	}, [language]);

	const toggleLanguage = (lang) => {
		setLanguage(lang);
	};

	return (
		<LanguageContext.Provider value={{ language, toggleLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
};

// Hook to access the context
export const useLanguage = () => useContext(LanguageContext);
