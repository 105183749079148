// App.js
import React from "react";
import Navbar from "../../components/Navbar.js";
import Banner from "./Banner_Home.js";
import NewsHome from "./NewsHome.js";
import SustainabilityHome from "./SustainabilityHome.js";
import Footer from "../../components/Footer.js";
import "../../App.css"; // Import file CSS untuk gaya yang sama

function App() {
	return (
		<div className="App">
			<Navbar />
			<Banner />
			<NewsHome />
			<SustainabilityHome />
			<Footer />
		</div>
	);
}

export default App;
