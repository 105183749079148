import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import Icon from "../../../asset/Vector.png";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const SocialPage = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);
	const [activeTabs, setActiveTabs] = useState([]); // Default to 'overview' tab
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [currentImage, setCurrentImage] = useState(0); // State to handle image carousel in overview

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
            query {
                generalPage {
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchSocialData = useCallback(async () => {
		const query = `
        query {
            socialPage (locale: "${language}") {
                Title
                Description
                Activity {
                    Title
                    Images { url formats }
                    Description
                    Reports {
                        Title
                        File { url }
                    }
                    Activities(
                        sort: ["Display_Order:desc", "Date:desc"]
                    ) {
                        Title
                        Image { url formats }
                        Description
                        Date
                        Display_Order
                    }
                }
                SEO {
                    Use_GeneralSEO
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                    }
                }
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			const result = await response.json();
			const fetchShareholding = result.data.socialPage;

			// Sort Activities in frontend if needed
			fetchShareholding.Activity.forEach((activity) => {
				activity.Activities.sort((a, b) => {
					if (b.Display_Order === a.Display_Order) {
						return new Date(b.Date) - new Date(a.Date);
					}
					return b.Display_Order - a.Display_Order;
				});
			});

			setData(fetchShareholding);
			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching social data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	useEffect(() => {
		fetchSocialData();
	}, [fetchSocialData, language]);

	useEffect(() => {
		if (data?.Activity?.length > 0 && activeTabs.length === 0) {
			setActiveTabs(new Array(data.Activity.length).fill("overview"));
		}
	}, [data]);

	const handleTabChange = (index, tab) => {
		const updatedTabs = [...activeTabs];
		updatedTabs[index] = tab; // Update tab yang aktif untuk index tertentu
		setActiveTabs(updatedTabs);
	};

	const handleOpenModal = (activity) => {
		setModalContent(activity);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	const { Title, Description, Activity } = data;
	const reports = Activity[0]?.Reports;
	const activities = Activity[0]?.Activities;
	const images = Activity[0]?.Images;

	return (
		<>
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}
				<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
					<section className="mb-10">
						<h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{Title}
						</h2>
						<MarkdownRenderer content={Description} />
						<h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{language === "id"
								? "Target/Rencana Kegiatan"
								: "Targets/Activity Plans"}
						</h2>
					</section>
					<section className="mb-12">
						{Activity?.length > 0 ? (
							Activity.map((activity, index) => (
								<div key={index}>
									<h2
										className="text-large md:text-2xl lg:text-3xl font-bold lg:mb-8 mb-4 text-left text-blue-900 mt-4"
										style={{
											color: "#2E3491",
											borderLeft: "8px solid red",
											paddingLeft: "10px",
										}}
									>
										{activity?.Title || "No Title"}
									</h2>

									{/* Tab Navigation */}
									<div className="flex space-x-4 mb-6">
										<button
											onClick={() => handleTabChange(index, "overview")}
											className={`${
												activeTabs[index] === "overview"
													? "bg-[#54A7D6] text-white border-2 border-blue-500"
													: "bg-gray-200 text-gray-800 border-2 border-gray-300"
											} px-4 py-2 rounded-md cursor-pointer`}
										>
											{language === "en" ? "Overview" : "Ikhtisar"}
										</button>
										<button
											onClick={() => handleTabChange(index, "activities")}
											className={`${
												activeTabs[index] === "activities"
													? "bg-[#54A7D6] text-white border-2 border-blue-500"
													: "bg-gray-200 text-gray-800 border-2 border-gray-300"
											} px-4 py-2 rounded-md cursor-pointer`}
										>
											{language === "en"
												? "Activities & Report"
												: "Kegiatan & Laporan"}
										</button>
									</div>

									{/* Overview Tab Content */}
									{activeTabs[index] === "overview" && (
										<div className="border-2 border-[#2E3491] p-4 sm:p-6">
											<div className="flex flex-col lg:flex-row lg:space-x-4">
												{/* Image Carousel */}
												<div className="w-full lg:w-1/2 xl:w-[600px]">
													{activity.Images?.length > 0 && (
														<img
															src={`${API_URL}${
																activity.Images[currentImage]?.formats?.medium
																	?.url || activity.Images[currentImage]?.url
															}`}
															alt="Overview"
															className="w-full aspect-[4/3] object-cover object-center rounded-lg"
														/>
													)}
													<div className="flex justify-center mt-4 space-x-2">
														{activity.Images?.map((_, imgIndex) => (
															<button
																key={imgIndex}
																onClick={() => setCurrentImage(imgIndex)}
																className={`${
																	currentImage === imgIndex
																		? "bg-blue-500"
																		: "bg-gray-300"
																} w-3 h-3 rounded-full`}
															></button>
														))}
													</div>
												</div>

												{/* Text Content */}
												<div className="w-full lg:flex-1 flex items-start">
													<div className="w-full">
														<MarkdownRenderer
															content={
																activity?.Description || "No Description"
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									{/* Activities & Report Tab Content */}
									{activeTabs[index] === "activities" && (
										<div className="border-2 border-[#2E3491] p-4 sm:p-6 rounded-md">
											{/* Reports */}
											<div className="mb-8">
												<h4 className="text-xl md:text-3xl font-bold mb-4 text-left">
													{language === "en" ? "Report" : "Laporan"}
												</h4>
												{activity.Reports?.length > 0 ? (
													activity.Reports.map((report, reportIndex) => (
														<a
															key={reportIndex}
															href={`${API_URL}${report?.File?.url || "#"}`}
															rel="noopener noreferrer"
															target="_blank"
															className="lg:text-xl text-base flex justify-between border-2 border-blue-900 px-4 py-2 rounded mb-4"
														>
															<span>{report?.Title || "Untitled Report"}</span>
															<img
																src={Icon}
																alt="Download"
																className="w-6 h-6"
															/>
														</a>
													))
												) : (
													<p className="text-gray-500">No reports available</p>
												)}
											</div>

											{/* Activities */}
											<div className="border-2 border-[#2E3491] p-4 rounded-md">
												<h4 className="text-lg sm:text-xl font-bold mb-4 text-left">
													{language === "en" ? "Activities" : "Kegiatan"}
												</h4>
												{activity.Activities?.length > 0 ? (
													<ul className="list-decimal ml-6 text-blue-500 space-y-2 text-left">
														{activity.Activities.map(
															(subActivity, actIndex) => (
																<li
																	key={actIndex}
																	className="cursor-pointer"
																	onClick={() => handleOpenModal(subActivity)}
																>
																	{subActivity?.Title || "Untitled Activity"}
																</li>
															)
														)}
													</ul>
												) : (
													<p className="text-gray-500">
														No activities available
													</p>
												)}
											</div>
										</div>
									)}
								</div>
							))
						) : (
							<p className="text-gray-500">No activities available</p>
						)}
					</section>
				</div>
			</div>

			{/* Modal for displaying activity details */}
			{isModalOpen && (
				<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto">
					<div className="bg-white p-6 sm:p-8 rounded-lg w-full max-w-2xl sm:max-w-3xl lg:max-w-4xl relative max-h-[85vh] overflow-y-auto min-h-[60vh]">
						{/* Close Button */}
						<button
							className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
							onClick={handleCloseModal}
						>
							&times;
						</button>

						{/* Image at the top */}
						<img
							src={`${API_URL}${
								modalContent?.Image[0]?.formats?.medium?.url ||
								modalContent?.Image[0]?.url
							}`}
							alt="Activity"
							className="w-full h-[250px] sm:h-[300px] lg:h-[350px] object-cover rounded-lg mb-4"
						/>

						{/* Title */}
						<h3 className="text-2xl sm:text-3xl font-bold mb-4 text-left text-gray-900">
							{modalContent?.Title || "Activity Detail"}
						</h3>

						{/* Description */}
						<div className="prose prose-lg sm:prose-xl">
							<MarkdownRenderer content={modalContent?.Description} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SocialPage;
