import React, { useState, useEffect, useCallback } from "react";
import BackgroundImage from "../asset/footer-bg.png";
import { FaPhone, FaFax, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { useLanguage } from "../context/LanguageContext";

function Footer() {
	const [companyData, setCompanyData] = useState(null);
	const [loading, setLoading] = useState(true);
	const { language } = useLanguage();

	const API_URL = process.env.REACT_APP_API_URL;

	// Fetch company and social media data
	const fetchCompanyData = useCallback(async () => {
		const url = `${API_URL}/graphql`;
		const query = `
            query GeneralPage {
                generalPage(locale: "${language}") {
                    Company_Name
                    Company_Description
                    Logo_CompanyFooter {
                        name
                        formats
                        url
                    }
                    Company_Address
                    Company_Phone
                    Company_Fax
                    Copyright
                    Company_Email
                    SocialMedia {
                        Title
                        Image {
                            name
                            formats
                            url
                        }
                        URL
                    }
                }
            }
        `;

		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
		};

		try {
			const response = await fetch(url, {
				method: "POST",
				headers,
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setCompanyData(result.data.generalPage);
		} catch (error) {
			console.error("Error fetching company data:", error.message);
		} finally {
			setLoading(false);
		}
	}, [API_URL, language]);

	// Set favicon when companyData is loaded
	useEffect(() => {
		if (companyData && companyData.Logo_CompanyFooter) {
			const faviconUrl = `${API_URL}${
				companyData.Logo_CompanyFooter.url || ""
			}`;
			const link =
				document.querySelector("link[rel='icon']") ||
				document.createElement("link");
			link.rel = "icon";
			link.href = `${faviconUrl}?v=${new Date().getTime()}`;
			document.head.appendChild(link);
		}
	}, [companyData, API_URL]);

	useEffect(() => {
		fetchCompanyData();
	}, [fetchCompanyData]);

	if (loading) {
		return <div className="font-dmSans">Loading...</div>;
	}

	if (!companyData) {
		return (
			<div className="font-dmSans">Error: Failed to load company data</div>
		);
	}

	return (
		<footer
			className="text-gray-700 py-8 border-t border-gray-200 relative z-20 font-dmSans"
			style={{
				backgroundColor: "white",
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				opacity: 0.9,
			}}
		>
			<div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
				{/* Left Column */}
				<div className="md:w-1/3 text-center md:text-left px-4">
					<img
						src={`${API_URL}${
							companyData.Logo_CompanyFooter?.formats?.thumbnail?.url ||
							companyData.Logo_CompanyFooter?.url ||
							""
						}`}
						alt={companyData.Logo_CompanyFooter.name}
						className="h-10 mb-6 mx-auto md:mx-0"
					/>
					<p className="text-base leading-relaxed break-words">
						{companyData.Company_Description}
					</p>

					<div className="flex justify-center md:justify-start space-x-4 mt-4">
						{companyData.SocialMedia.map((social, index) => (
							<a
								key={index}
								href={social.URL}
								aria-label={social.Title}
								className="flex items-center space-x-2"
								target="_blank"
								rel="noopener noreferrer"
							>
								{/* Render Social Media Image */}
								<img
									src={`${API_URL}${social.Image.url}`}
									alt={social.Image.name}
									className="h-6 w-6 object-contain"
								/>
							</a>
						))}
					</div>
					<p className="text-sm mt-4">{companyData.Copyright}</p>
				</div>

				{/* Right Column */}
				<div className="md:w-1/3 text-center md:text-left px-4">
					<p className="text-sm font-bold">{companyData.Company_Name}</p>
					<div className="flex flex-col items-center md:items-start mt-2">
						<div className="flex items-start mt-2">
							<FaMapMarkerAlt className="mr-2 hidden md:inline-block" />
							<p className="text-sm text-center md:text-left">
								{companyData.Company_Address}
							</p>
						</div>
						<div className="flex items-start mt-2">
							<FaPhone className="mr-2" />
							<p className="text-sm">Phone: {companyData.Company_Phone}</p>
						</div>
						<div className="flex items-start mt-2">
							<FaFax className="mr-2" />
							<p className="text-sm">Fax: {companyData.Company_Fax}</p>
						</div>
						<div className="flex items-start mt-2">
							<FaEnvelope className="mr-2" />
							<p className="text-sm">Email: {companyData.Company_Email}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
