import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Helmet } from "react-helmet";

const ValueCard = ({ title, description, image }) => (
	<div className="flex flex-col md:flex-row items-center p-4 bg-transparan border-transparan rounded-lg mb-8">
		<div className="flex flex-col items-center justify-center w-[160px] h-[160px] bg-white border-transparan rounded-lg shadow-md mb-4 md:mb-0 md:mr-6">
			<img
				src={image}
				alt={`${title} Icon`}
				className="w-20 h-25 my-2"
			/>
			{/* <h3 className="lg:text-xl md:text-xl text-xl font-bold text-blue-900 font-helvetica">
				{title}
			</h3> */}
		</div>
		<div className="flex-1 text-center md:text-left">
			<h3 className="lg:text-xl md:text-xl text-xl font-bold text-blue-900 font-helvetica">
				{title}
			</h3>
			<MarkdownRenderer content={description}></MarkdownRenderer>
		</div>
	</div>
);

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const Overview = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);
	const videoRef = useRef(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						MetaSocial {
							Title
							Social_Network
							Description
							Image { url }
						}
						Keywords
						MetaRobots
						StructuredData
						MetaViewPort
						CanonicalURL
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchOverviewData = useCallback(async () => {
		const query = `
			query {
				overviewPage(locale: "${language}") {
					Title
					Paragraph_Title
					Paragraph_Content
					Vision_Description
					Mission_Description
					Corporate_Value_Description
					Value1_Title
					Value1_Description
					Value1_Image { 
						url
						formats 
					}
					Value2_Title
					Value2_Description
					Value2_Image { 
						url
						formats 
					}
					Value3_Title
					Value3_Description
					Value3_Image { 
						url
						formats 
					}
					Value4_Title
					Value4_Description
					Value4_Image { 
						url
						formats 
					}
					SEO {
						Use_GeneralSEO
						SEO {
							MetaTitle
							MetaDescription
							ShareImage { url }
							MetaSocial {
								Title
								Social_Network
								Description
								Image { url }
							}
							Keywords
							MetaRobots
							StructuredData
							MetaViewPort
							CanonicalURL
						}
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const overviewData = result.data.overviewPage;
			setData(overviewData);

			if (overviewData.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(overviewData.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchOverviewData();
	}, [fetchOverviewData]);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.playbackRate = 0.9;
		}
	}, []);

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen font-helvetica">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const values = [
		{
			title: data.Value1_Title,
			description: data.Value1_Description,
			image: `${API_URL}${
				data.Value1_Image?.formats?.thumbnail?.url || data.Value1_Image?.url
			}`,
		},
		{
			title: data.Value2_Title,
			description: data.Value2_Description,
			image: `${API_URL}${
				data.Value2_Image?.formats?.thumbnail?.url || data.Value2_Image?.url
			}`,
		},
		{
			title: data.Value3_Title,
			description: data.Value3_Description,
			image: `${API_URL}${
				data.Value3_Image?.formats?.thumbnail?.url || data.Value3_Image?.url
			}`,
		},
		{
			title: data.Value4_Title,
			description: data.Value4_Description,
			image: `${API_URL}${
				data.Value4_Image?.formats?.thumbnail?.url || data.Value4_Image?.url
			}`,
		},
	];

	return (
		<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
			{setSEOTags()}
			<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
				<section className="mb-10">
					<h2
						className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
						style={{ borderLeft: "8px solid red", paddingLeft: "10px" }}
					>
						{data.Paragraph_Title}
					</h2>
					<MarkdownRenderer content={data.Paragraph_Content}></MarkdownRenderer>
				</section>

				<section className="mb-12 flex flex-col items-start w-full p-0 md:flex-row md:justify-center">
					<div className="w-full p-0 md:w-10/12 md:px-4">
						{" "}
						{/* Kolom utama di mobile dan desktop */}
						<h2 className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-center text-blue-900">
							{language === "en" ? "Vision" : "Visi"}
						</h2>
						<MarkdownRenderer content={data.Vision_Description} />
					</div>
				</section>

				<section className="mb-12 flex flex-col items-start w-full p-0 md:flex-row md:justify-center">
					<div className="w-full p-0 md:w-10/12 md:px-4">
						{" "}
						{/* Kolom utama di mobile dan desktop */}
						<h2 className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-center text-blue-900">
							{language === "en" ? "Mission" : "Misi"}
						</h2>
						<MarkdownRenderer content={data.Mission_Description} />
					</div>
				</section>

				<section className="mb-12">
					<h2 className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900">
						{language === "en" ? "Corporate Value" : "Nilai Perusahaan"}
					</h2>
					<MarkdownRenderer content={data.Corporate_Value_Description} />
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
						{values.map((value, index) => (
							<ValueCard
								key={index}
								title={value.title}
								description={value.description}
								image={value.image}
							/>
						))}
					</div>
				</section>
			</div>
		</div>
	);
};

export default Overview;
