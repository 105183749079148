// Import necessary libraries and components
import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4"; // Google Analytics
import { LanguageProvider } from "./context/LanguageContext";
import ParticlesBackground from "./context/ParticlesBackground"; // Import ParticlesBackground

// Import page components with PascalCase naming convention
import Home from "./Pages/Home/Home";
import AboutOverview from "./Pages/About/Overview/Overview";
import AboutShareholdingStructure from "./Pages/About/ShareholdingStructure/ShareholdingStructure";
import AboutOrganizationGroupStructure from "./Pages/About/OrganizationGroupStructure/OrganizationGrubStructure";
import CompanyGroupStructure from "./Pages/About/CompanyGroupStructure/CompanyGroupStructure";
import AboutHistory from "./Pages/About/History/History";
import AboutNews from "./Pages/About/News/News";
import AboutNewsDetail from "./Pages/About/News/News_Detail/NewsDetail";
import InvestorProspectus from "./Pages/Investor/Prospectus/Prospectus";
import InvestorFinancialReport from "./Pages/Investor/Financial Report/Financial";
import InvestorDisclosure from "./Pages/Investor/Disclosure of Information/Disclosure";
import InvestorGeneral from "./Pages/Investor/General Meeting of Shareholder/General";
import InvestorAnnual from "./Pages/Investor/Annual Report and Sustainability Report/Annual";
import BusinessNewPage from "./Pages/Business/NewsBisnis/Newspage";
import BusinessTrading from "./Pages/Business/Trading/Trading";
import BusinessShipmentManagement from "./Pages/Business/Shipment Management/ShipmentManagement";
import BusinessShipmentLNG from "./Pages/Business/Shipment LNG/ShipmentLNG";
import BOCDirector from "./Pages/BOC/Director/Director";
import BOCCommissioner from "./Pages/BOC/Commissioner/Commissioner";
import BOCCommitteeAudit from "./Pages/BOC/Committee/Audit Committee/Committee";
import BOCCommitteeNomination from "./Pages/BOC/Committee/Nomination & Remuneration Commitee/Nomination";
import BOCCommitteeESG from "./Pages/BOC/Committee/ESG Committee/ESG";
import SustainabilityEnv from "./Pages/Sustainability/Environment/Environment";
import SustainabilitySocial from "./Pages/Sustainability/Social/Social";
import SustainabilityGovernance from "./Pages/Sustainability/Governance/Governance";
import Contact from "./Pages/Contact/Contact";

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize("G-XXXXXXXXXX"); // Replace with your Measurement ID

// Component to track route changes for Google Analytics
const AnalyticsTracker = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname });
	}, [location]);

	return null;
};

function App() {
	return (
		// Wrap the entire app with LanguageProvider and Router
		<LanguageProvider>
			<Router>
				<AnalyticsTracker /> {/* Add this to track page views */}
				<ParticlesBackground />{" "}
				{/* Add ParticlesBackground to render on all pages */}
				<div>
					{/* Define all routes within Routes */}
					<Routes>
						<Route
							path="/"
							element={<Navigate to="/home" />}
						/>
						<Route
							path="/home"
							element={<Home />}
						/>
						<Route
							path="/beranda"
							element={<Home />}
						/>
						<Route
							path="/about/overview"
							element={<AboutOverview />}
						/>
						<Route
							path="/about/shareholding"
							element={<AboutShareholdingStructure />}
						/>
						<Route
							path="/about/organization"
							element={<AboutOrganizationGroupStructure />}
						/>
						<Route
							path="/about/companygrub"
							element={<CompanyGroupStructure />}
						/>
						<Route
							path="/about/history"
							element={<AboutHistory />}
						/>
						<Route
							path="/about/news"
							element={<AboutNews />}
						/>
						<Route
							path="/about/news/detail/:id"
							element={<AboutNewsDetail />}
						/>
						<Route
							path="/investor/prospectus"
							element={<InvestorProspectus />}
						/>
						<Route
							path="/investor/financialreport"
							element={<InvestorFinancialReport />}
						/>
						<Route
							path="/investor/disclosure"
							element={<InvestorDisclosure />}
						/>
						<Route
							path="/investor/generalmeetting"
							element={<InvestorGeneral />}
						/>
						<Route
							path="/investor/annualreport"
							element={<InvestorAnnual />}
						/>
						<Route
							path="/business/trading"
							element={<BusinessTrading />}
						/>
						<Route
							path="/business/newpage"
							element={<BusinessNewPage />}
						/>
						<Route
							path="/business/shipmanagement"
							element={<BusinessShipmentManagement />}
						/>
						<Route
							path="/business/shipment"
							element={<BusinessShipmentLNG />}
						/>
						<Route
							path="/BOC/directors"
							element={<BOCDirector />}
						/>
						<Route
							path="/BOC/commissioners"
							element={<BOCCommissioner />}
						/>
						<Route
							path="/BOC/commitee/audit"
							element={<BOCCommitteeAudit />}
						/>
						<Route
							path="/BOC/commitee/nomination"
							element={<BOCCommitteeNomination />}
						/>
						<Route
							path="/BOC/commitee/ESG"
							element={<BOCCommitteeESG />}
						/>
						<Route
							path="/sustainability/environment"
							element={<SustainabilityEnv />}
						/>
						<Route
							path="/sustainability/social"
							element={<SustainabilitySocial />}
						/>
						<Route
							path="/sustainability/governance"
							element={<SustainabilityGovernance />}
						/>
						<Route
							path="/contact"
							element={<Contact />}
						/>
						<Route
							path="/kontak"
							element={<Contact />}
						/>
					</Routes>
				</div>
			</Router>
		</LanguageProvider>
	);
}

export default App;
