// App.js
import React from "react";
import Navbar from "../../../components/Navbar.js";
import Footer from "../../../components/Footer.js";
import Banner from "./BannerFinancial.js";
import Content from "./ContentFinancial.js";
import "../../../App.css"; // Import file CSS untuk gaya yang sama

function App() {
	return (
		<div className="App">
			<Navbar />
			<Banner />
			<Content />
			<Footer />
		</div>
	);
}

export default App;
