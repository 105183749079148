import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../../context/LanguageContext";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Komponen untuk merender konten markdown
const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-2xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);
const NewsDetail = () => {
	const [newsData, setNewsData] = useState(null);
	const [News, setNews] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [elapsedTime, setElapsedTime] = useState("");
	const [pageInput, setPageInput] = useState(""); // State untuk input halaman
	const { language } = useLanguage();
	const itemsPerPage = 3;
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const getRelativeTime = (date) => {
		const now = new Date();
		const targetDate = new Date(date);
		const diffInSeconds = Math.floor((now - targetDate) / 1000);

		const minutes = Math.floor(diffInSeconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) {
			return `${days} hari yang lalu`;
		} else if (hours > 0) {
			return `${hours} jam yang lalu`;
		} else if (minutes > 0) {
			return `${minutes} menit yang lalu`;
		} else {
			return "Baru saja";
		}
	};

	const getSlugFromURL = () => {
		const urlParts = window.location.pathname.split("/");
		return urlParts[urlParts.length - 1] || "";
	};

	const fetchNewsUpdate = useCallback(async () => {
		const slug = getSlugFromURL(); // Memanggil fungsi untuk mendapatkan slug dari URL
		const query = `
        query NewsUpdateCollectionPages {
            newsUpdateCollectionPages(
			filters: { Slug: { eq: "${slug}" } }) {
                Title
				Date
                Article
                Slug
				publishedAt
                Images {
                    Title
                    Image {
                        formats
                        url
                    }
                }
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const news = result.data.newsUpdateCollectionPages[0];
			setNewsData(news);
			const calculateElapsedTime = (Date) => {
				const publishedDate = new Date(Date);
				const now = new Date();
				const diffInMs = now - publishedDate;
				const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

				if (diffInMinutes < 60) {
					return language === "en"
						? `${diffInMinutes} minutes ago`
						: `${diffInMinutes} menit yang lalu`;
				} else if (diffInMinutes < 1440) {
					const diffInHours = Math.floor(diffInMinutes / 60);
					return language === "en"
						? `${diffInHours} hours ago`
						: `${diffInHours} jam yang lalu`;
				} else {
					const diffInDays = Math.floor(diffInMinutes / 1440);
					return language === "en"
						? `${diffInDays} days ago`
						: `${diffInDays} hari yang lalu`;
				}
			};

			if (news?.Date) {
				setElapsedTime(calculateElapsedTime(news.Date));
			}
		} catch (error) {
			console.error("Error fetching news data:", error.message);
		}
	}, [API_URL, language]);

	const getNewsCount = useCallback(async () => {
		const query = `
            query newsUpdateCollectionPages_connection {
                newsUpdateCollectionPages_connection {
                    pageInfo {
                        total
                        page
                        pageSize
                        pageCount
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			const result = await response.json();
			const totalItems =
				result.data.newsUpdateCollectionPages_connection.pageInfo.total;
			// Hitung jumlah halaman berdasarkan total item dan jumlah item per halaman
			setTotalPages(Math.ceil(totalItems / itemsPerPage));
		} catch (error) {
			console.error("Error fetching news count:", error.message);
		}
	}, [API_URL]);

	const fetchCardNews = useCallback(
		async (page = 1) => {
			const query = `
            query {
                newsUpdateCollectionPages(
				pagination: { page: ${page}, pageSize: ${itemsPerPage} }
                sort: ["Display_Order:desc", "Date:desc"]
                filters: { Display_Order: { not: null }, Date: { not: null } }
                ) {
                    Title
                    Article
					Short_Description
					Date
					publishedAt
                    Images {
                        Title
                        Image {
                            formats
                            url
                        }
                    }
                    Slug
                }
            }
        `;
			try {
				const response = await fetch(`${API_URL}/graphql`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
					},
					body: JSON.stringify({ query }),
				});
				const result = await response.json();
				setNews(result.data.newsUpdateCollectionPages);
			} catch (error) {
				console.error("Error fetching report data:", error.message);
			}
		},
		[API_URL, itemsPerPage]
	);

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
			query {
				generalPage {
					SEO {
						MetaTitle
						MetaDescription
						ShareImage { url }
						MetaSocial {
							Title
							Social_Network
							Description
							Image { url }
						}
						Keywords
						MetaRobots
						StructuredData
						MetaViewPort
						CanonicalURL
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchNews = useCallback(async () => {
		const query = `
        query {
            newsUpdatePage {
                Title
                SEO {
                    Use_GeneralSEO
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.newsUpdatePage;
			setData(fetchShareholding);

			// Pastikan fetchGeneralSEOData dipanggil hanya saat Use_GeneralSEO benar
			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData(); // Tidak perlu ditambahkan ke dependensi
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, fetchGeneralSEOData]); // Tidak menambahkan fetchGeneralSEOData sebagai dependensi

	useEffect(() => {
		fetchNewsUpdate();
		getNewsCount();
		fetchNews();
	}, [fetchNewsUpdate, getNewsCount, fetchNews]);

	useEffect(() => {
		fetchCardNews(currentPage);
	}, [currentPage, fetchCardNews]);

	if (!data && !newsData && News.length === 0) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const images = newsData?.Images || [];

	const handleFirstPage = () => setCurrentPage(1);
	const handlePreviousPage = () =>
		setCurrentPage((prev) => Math.max(prev - 1, 1));
	const handlePageSelect = (page) => setCurrentPage(page);
	const handleNextPage = () =>
		setCurrentPage((prev) => Math.min(prev + 1, totalPages));
	const handleLastPage = () => setCurrentPage(totalPages);

	const handlePageInputChange = (event) => {
		setPageInput(event.target.value);
	};

	const handlePageSearch = () => {
		const page = Number(pageInput);
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		} else {
			alert(`Please enter a valid page number between 1 and ${totalPages}`);
		}
	};

	const handleNextImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const handlePrevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social?.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	return (
		<>
			<div className="w-full h-full p-4 relative z-20 mb-12 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}

				<section className="relative mb-12 mt-8 px-4 lg:px-24">
					{/* Updated Image Section */}
					<div className="relative">
						<img
							className="w-full h-[75vw] sm:h-[56.25vw] md:h-[60vw] lg:h-[45vw] object-cover rounded-lg"
							src={`${API_URL}${
								images[currentImageIndex]?.Image?.formats?.medium?.url ||
								images[currentImageIndex]?.Image?.url
							}`}
							alt={images[currentImageIndex]?.Title || "News"}
						/>

						{images.length > 1 && (
							<>
								<button
									onClick={handlePrevImage}
									className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-all"
								>
									{"<"}
								</button>
								<button
									onClick={handleNextImage}
									className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-all"
								>
									{">"}
								</button>
							</>
						)}
					</div>

					{/* Text Content */}
					<div className=" mt-4">
						<h3
							className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 lg:text-5xl font-bold mb-4 text-left"
							style={{ color: "#2E3491" }}
						>
							{newsData?.Title}
						</h3>

						<p className="text-red-500 lg:text-2xl md:text-xl text-base mb-2 text-left">
							{elapsedTime}
						</p>
						<MarkdownRenderer content={newsData?.Article} />
					</div>
				</section>

				<section className="mb-12 px-4 lg:px-24">
					<div>
						<div className="flex items-center justify-between mb-8 sm:mb-10">
							<h2
								className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 font-bold text-left "
								style={{
									color: "#2E3491",
									borderLeft: "8px solid red",
									paddingLeft: "10px",
								}}
							>
								{data?.Title}
							</h2>
							<button
								onClick={() => (window.location.href = "/about/news")}
								className="bg-[#54A7D6] text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
							>
								{language === "en"
									? "Back to All News"
									: "Kembali ke Semua Berita"}
							</button>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
							{News.map((item, index) => (
								<a
									key={index}
									href={`/about/news/detail/${item.Slug}`}
									rel="noopener noreferrer"
									className="flex flex-col bg-white bg-opacity-75 rounded-lg shadow-lg overflow-hidden h-full"
								>
									<img
										className="w-full h-auto object-cover aspect-[4/3] rounded-lg"
										src={`${API_URL}${
											item.Images[0]?.Image?.formats?.small?.url ||
											item.Images[0]?.Image?.formats?.Small?.url ||
											item.Images[0]?.Image?.url
										}`}
										alt="News"
									/>
									<div className="flex flex-col justify-between flex-grow p-4 text-left">
										<div>
											<p className="text-gray-500 text-sm mb-2">
												{new Date(item.Date).toLocaleDateString("id-ID", {
													year: "numeric",
													month: "long",
													day: "2-digit",
												})}
												, ({getRelativeTime(item.Date)})
											</p>
											<h3 className="lg:text-3xl md:text-2xl sm:text-large font-semibold text-gray-800 mb-2">
												{item?.Title}
											</h3>
											<p className="text-gray-600 mb-4 lg:text-xl md:text-large text-base">
												<span className="lg:hidden sm:hidden md:inline">
													{item?.Short_Description.slice(0, 100) +
														(item?.Short_Description.length > 100 ? "..." : "")}
												</span>
												<span className="lg:inline sm:inline md:hidden">
													{item?.Short_Description}
												</span>
											</p>
										</div>
										<button className="bg-[#54A7D6] text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-auto">
											{language === "en" ? "Read More" : "Baca Selengkapnya"}
										</button>
									</div>
								</a>
							))}
						</div>
					</div>

					{/* Container for Input Page and Pagination */}
					<div className="flex flex-col sm:flex-row items-center justify-between my-4 space-y-2 sm:space-y-0 sm:space-x-4">
						{/* Input Page */}
						<div className="flex items-center">
							<label
								className="mr-2"
								style={{ color: "#2E3491" }}
							>
								Input Page
							</label>
							<input
								type="number"
								value={pageInput}
								onChange={handlePageInputChange}
								className="border border-gray-300 rounded-lg px-2 py-1"
								style={{ borderColor: "#2E3491", width: "60px" }}
							/>
							<button
								onClick={handlePageSearch}
								className="ml-2 px-4 py-1 bg-[#54A7D6] text-white rounded-lg hover:bg-blue-700"
							>
								{language === "en" ? "View" : "Lihat"}
							</button>
						</div>

						{/* Pagination Controls */}
						<div className="flex flex-wrap justify-center sm:justify-end space-x-2 text-blue-900">
							<button
								onClick={handleFirstPage}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1 ? "text-gray-400" : "text-blue-900"
								}`}
							>
								&laquo; First
							</button>
							<button
								onClick={handlePreviousPage}
								disabled={currentPage === 1}
								className={`px-2 py-1 ${
									currentPage === 1 ? "text-gray-400" : "text-blue-900"
								}`}
							>
								&lsaquo; Prev
							</button>

							{/* Page numbers */}
							{Array.from({ length: totalPages }, (_, i) => i + 1).map(
								(page) => (
									<button
										key={page}
										onClick={() => handlePageSelect(page)}
										className={`px-2 py-1 ${
											currentPage === page
												? "text-red-500 font-bold"
												: "text-blue-900"
										}`}
									>
										{page}
									</button>
								)
							)}

							<button
								onClick={handleNextPage}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages ? "text-gray-400" : "text-blue-900"
								}`}
							>
								Next &rsaquo;
							</button>
							<button
								onClick={handleLastPage}
								disabled={currentPage === totalPages}
								className={`px-2 py-1 ${
									currentPage === totalPages ? "text-gray-400" : "text-blue-900"
								}`}
							>
								Last &raquo;
							</button>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default NewsDetail;
