import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useLanguage } from "../../context/LanguageContext";
import { Helmet } from "react-helmet";

function HeroSection() {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
      query {
        generalPage {
          SEO {
            MetaTitle
            MetaDescription
            ShareImage { url }
            MetaSocial {
              Title
              Social_Network
              Description
              Image { url }
            }
            Keywords
            MetaRobots
            StructuredData
            MetaViewPort
            CanonicalURL
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchHome = useCallback(async () => {
		const query = `
      query {
        home(locale: "${language}") {
          Title
          Sub_Tittle
          Message_Line1
          Message_Line2
          Home_Banner {
            Banner_Image {
              url
              formats
            }
            Image_AltText
            Link_Url
            Display_Order
          }
          SEO {
            Use_GeneralSEO
            SEO {
              MetaTitle
              MetaDescription
              ShareImage { url }
              MetaSocial {
                Title
                Social_Network
                Description
                Image { url }
              }
              Keywords
              MetaRobots
              StructuredData
              MetaViewPort
              CanonicalURL
            }
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchedData = result.data.home;
			setData(fetchedData);

			if (fetchedData.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchedData.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching home data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={
					seoData?.MetaDescription || "Default description for the website"
				}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchHome();
	}, [fetchHome]);

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen font-helvetica">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<>
			{setSEOTags()}
			<style jsx>{`
				@tailwind base;
				@tailwind components;
				@tailwind utilities;

				@layer utilities {
					@keyframes zoom {
						0%,
						100% {
							transform: scale(1);
						}
						50% {
							transform: scale(1.1);
						}
					}

					.animate-zoom {
						animation: zoom 8s forwards;
					}
				}
			`}</style>
			<section className="font-helvetica">
				{/* Mobile Menu */}
				<div className="block md:hidden">
					<Swiper
						modules={[Autoplay]}
						className="w-full h-full"
						loop={true}
						autoplay={{
							delay: 4000, // 4 seconds interval
							disableOnInteraction: false,
						}}
					>
						{data.Home_Banner.map((banner, index) => (
							<SwiperSlide key={index}>
								<a
									href={banner.Link_Url}
									rel="noopener noreferrer"
								>
									<div className="relative h-[40vh] bg-cover bg-center bg-contain flex items-center justify-start overflow-hidden">
										<div
											className="absolute inset-0 bg-cover bg-center transform animate-zoom"
											style={{
												backgroundImage: `url(${API_URL}${
													banner?.Banner_Image?.formats?.large?.url ||
													banner?.Banner_Image?.url
												})`,
												loading: "lazy",
											}}
										></div>
										<div className="absolute inset-0 bg-black bg-opacity-10"></div>
										<div
											className="relative z-10 text-left text-white p-4 max-w-md"
											style={{ marginTop: "10vh" }}
										>
											<h1 className="text-xl font-bold mb-2">{data.Title}</h1>
											<p className="text-sm mb-2">{data.Sub_Tittle}</p>
										</div>
									</div>
								</a>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				{/* Tablet Menu */}
				<div className="hidden md:flex lg:hidden">
					<Swiper
						navigation={true}
						modules={[Navigation, Autoplay]}
						className="w-full h-full"
						loop={true}
						autoplay={{
							delay: 4000, // 4 seconds interval
							disableOnInteraction: false,
						}}
					>
						{data.Home_Banner.map((banner, index) => (
							<SwiperSlide key={index}>
								<a
									href={banner.Link_Url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="relative h-[50vh] bg-cover bg-center flex items-center justify-start overflow-hidden">
										<div
											className="absolute inset-0 bg-cover bg-center transform animate-zoom"
											style={{
												backgroundImage: `url(${API_URL}${banner.Banner_Image.url})`,
												loading: "lazy",
											}}
										></div>
										<div className="absolute inset-0 bg-black bg-opacity-10"></div>
										<div className="relative z-10 text-left text-white p-6 max-w-3xl ml-10">
											<h1 className="text-5xl font-bold mb-4">{data.Title}</h1>
											<p className="text-2xl mb-4">{data.Sub_Tittle}</p>
										</div>
									</div>
								</a>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				{/* Desktop Menu */}
				<div className="hidden lg:flex">
					<Swiper
						navigation={true}
						modules={[Navigation, Autoplay]}
						className="w-full h-full"
						loop={true}
						autoplay={{
							delay: 4000, // 4 seconds interval
							disableOnInteraction: false,
						}}
					>
						{data.Home_Banner.map((banner, index) => (
							<SwiperSlide key={index}>
								<a
									href={banner.Link_Url}
									rel="noopener noreferrer"
								>
									<div className="relative h-[70vh] bg-cover bg-center flex items-center justify-start overflow-hidden">
										<div
											className="absolute inset-0 bg-cover bg-center transform animate-zoom"
											style={{
												backgroundImage: `url(${API_URL}${banner.Banner_Image.url})`,
												loading: "lazy",
											}}
										></div>
										<div className="absolute inset-0 bg-black bg-opacity-10"></div>
										<div className="relative z-10 text-left text-white p-6 max-w-7xl ml-12 lg:ml-20">
											<h1 className="text-5xl font-bold mb-6">{data.Title}</h1>
											<p className="text-2xl mb-4">{data.Sub_Tittle}</p>
										</div>
									</div>
								</a>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				{/* Background Gradient Block */}
				<div
					className="py-6 sm:py-8 md:py-10 relative z-20"
					style={{
						background: "linear-gradient(90deg, #288CC3 0%, #9CC9E2 100%)",
					}}
				>
					<div className="max-w-7xl mx-auto text-center text-white px-4 sm:px-6 md:px-8 relative z-20">
						<h2 className="text-base sm:text-sm md:text-xl font-semibold">
							{data.Message_Line1}
						</h2>
						<p className="text-sm sm:text-base md:text-lg mt-2">
							{data.Message_Line2}
						</p>
					</div>
				</div>
			</section>
		</>
	);
}

export default HeroSection;
