import React, { useEffect, useState } from "react";
import { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles"; // Use loadFull for complete features

const ParticlesBackground = () => {
	const [init, setInit] = useState(false);

	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadFull(engine);

			engine.load({
				id: "tsparticles",
				options: {
					autoPlay: true,
					background: {
						color: {
							value: "#F5F5F5",
						},
					},
					fullScreen: {
						enable: true,
						zIndex: -10, // Ensure background stays at the very back
					},
					fpsLimit: 30,
					interactivity: {
						detectsOn: "window",
						events: {
							onHover: {
								enable: true,
								mode: "slow",
								parallax: {
									enable: false,
								},
							},
							onClick: {
								enable: false,
							},
							resize: {
								enable: true,
							},
						},
						modes: {
							slow: {
								factor: 0.5,
								radius: 150,
							},
						},
					},
					particles: {
						number: {
							value: 60,
							density: {
								enable: true,
								area: 800,
							},
						},
						color: {
							value: "49ABE1",
						},
						move: {
							enable: true,
							speed: 0.7,
							direction: "none",
							outModes: {
								default: "out",
							},
						},
						size: {
							value: {
								min: 2,
								max: 4,
							},
						},
						opacity: {
							value: 0.5,
							animation: {
								enable: false,
							},
						},
						links: {
							enable: true,
							distance: 150,
							color: "#076599",
							opacity: 0.4,
							width: 1,
						},
					},
					pauseOnBlur: true,
					pauseOnOutsideViewport: true,
				},
			});
		}).then(() => {
			setInit(true);
		});
	}, []);

	return init ? (
		<div
			id="tsparticles"
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
			}}
		></div>
	) : null;
};

export default ParticlesBackground;
