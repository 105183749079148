import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

const ShipmentManagement = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
            query {
                generalPage {
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchShipmentManagement = useCallback(async () => {
		const query = `
			query {
				shipManagementPage(locale: "${language}") {
					Title
					Description
					Product_Title
					Business_Images {
						Title
						Image {
							url
							formats
						}
					}
					Product {
						Description
						Image {
							formats
							url
						}
					}
					SEO {
						Use_GeneralSEO
						SEO {
							MetaTitle
							MetaDescription
							ShareImage { url }
							MetaSocial {
								Title
								Social_Network
								Description
								Image { url }
							}
							Keywords
							MetaRobots
							StructuredData
							MetaViewPort
							CanonicalURL
						}
					}
				}
			}
		`;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.shipManagementPage;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={seoData?.MetaDescription || "Default description"}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			{seoData?.MetaSocial &&
				seoData.MetaSocial.map((social, index) => (
					<meta
						key={index}
						property="og:title"
						content={social.Title}
					/>
				))}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchShipmentManagement();
	}, [fetchShipmentManagement]);

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	const products = data.Product || [];

	return (
		<>
			{setSEOTags()}
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
					<section className="mb-10">
						{/* <h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{data.Title}
						</h2> */}
						<img
							src={`${API_URL}${
								data.Business_Images.Image?.large?.url ||
								data.Business_Images.Image?.url
							}`}
							alt={data.Business_Images.Title}
							className="w-full h-auto rounded-lg mb-4"
							style={{
								width: "100%",
								height: "auto",
								objectFit: "cover",
								maxHeight: "50vh", // Membatasi tinggi maksimal agar tidak terlalu tinggi di layar
							}}
						/>
						<MarkdownRenderer content={data.Description} />
						<h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{data.Product_Title}
						</h2>
						{/* Grid Produk */}
						{products.length === 1 || products.length === 7 ? (
							<div className="flex flex-wrap justify-center gap-6 max-w-5xl mx-auto">
								{products.map((product, index) => (
									<div
										key={index}
										className="border-2 border-blue-600 rounded-md p-4 flex items-center justify-center text-center"
										style={{ minWidth: "200px", maxWidth: "300px" }}
									>
										<img
											src={`${API_URL}${product.Image?.url || ""}`}
											alt="Product showcasing detailed features"
											className="mr-4 w-[50px] h-[50px] object-cover" // Further reduced dimensions
										/>
										<p className="text-gray-700 text-left">
											{product.Description}
										</p>
									</div>
								))}
							</div>
						) : products.length === 5 ? (
							// Layout for 5 items
							<div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
								<div className="col-span-3 grid grid-cols-1 md:grid-cols-3 gap-6">
									{products.slice(0, 3).map((product, index) => (
										<div
											key={index}
											className="border-2 border-blue-600 rounded-md p-4 flex items-center justify-center text-center"
										>
											<img
												src={`${API_URL}${product.Image?.url || ""}`}
												alt="Product showcasing detailed features"
												className="mr-4 w-[50px] h-[50px] object-cover" // Further reduced dimensions
											/>
											<p className="text-gray-700 text-left">
												{product.Description}
											</p>
										</div>
									))}
								</div>
								<div className="col-span-3 grid grid-cols-1 md:grid-cols-2 gap-6">
									{products.slice(3, 5).map((product, index) => (
										<div
											key={index}
											className="border-2 border-blue-600 rounded-md p-4 flex items-center justify-center text-center"
										>
											<img
												src={`${API_URL}${product.Image?.url || ""}`}
												alt="Product showcasing detailed features"
												className="mr-4 w-[50px] h-[50px] object-cover" // Further reduced dimensions
											/>
											<p className="text-gray-700 text-left">
												{product.Description}
											</p>
										</div>
									))}
								</div>
							</div>
						) : (
							// Default layout
							<div
								className={`col-span-3 grid grid-cols-1 md:grid-cols-${
									products.length % 2 === 0 ? 2 : 3
								} gap-6 max-w-5xl mx-auto`}
							>
								{products.map((product, index) => (
									<div
										key={index}
										className="border-2 border-blue-600 rounded-md p-4 flex items-center justify-center text-center"
									>
										<img
											src={`${API_URL}${product.Image?.url || ""}`}
											alt="Product showcasing detailed features"
											className="mr-4 w-[50px] h-[50px] object-cover" // Further reduced dimensions
										/>
										<p className="text-gray-700 text-left">
											{product.Description}
										</p>
									</div>
								))}
							</div>
						)}
					</section>
				</div>
			</div>
		</>
	);
};

export default ShipmentManagement;
