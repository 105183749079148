import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";

function Navbar() {
	const [menus, setMenus] = useState([]);
	const [logo, setLogo] = useState(null); // Single logo URL
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState("");
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [showNewBusinessPage, setShowNewBusinessPage] = useState(false);
	const { language, toggleLanguage } = useLanguage();
	const navRef = useRef(null);

	const API_URL = process.env.REACT_APP_API_URL;

	// Mengambil data Logo dari General Page
	const fetchLogo = useCallback(async () => {
		const url = `${API_URL}/graphql`;
		const query = `
            query GeneralPage {
                generalPage (locale: "${language}"){
					Show_NewBusinessPage
                    Logo_CompanyHeader {
                        name
                        formats
                        url
                    }
                }
            }
        `;

		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
		};

		try {
			const response = await fetch(url, {
				method: "POST",
				headers,
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setLogo(result.data.generalPage.Logo_CompanyHeader);
			setShowNewBusinessPage(result.data.generalPage.Show_NewBusinessPage);
		} catch (error) {
			console.error("Error fetching logo data:", error.message);
		}
	}, [API_URL, language]);

	// Mengambil data menu
	// Mengambil data menu
	const fetchMenus = useCallback(
		async (language) => {
			const url = `${API_URL}/graphql`;
			const query = `
        query {
            menus(locale: "${language}") {
                Name_Menu
                Display_Order
                sub_menu {
                    Name_SubMenu
                    Banner_Submenu {
                        name
                        formats
                        url
                    }
                    Description_Submenu
                    Sub_Menu {
                        Submenu
                        Code_Page
                        Display_Order
                    }
                }
            }
        }
    `;

			const headers = {
				"Content-Type": "application/json",
				Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
			};

			try {
				const response = await fetch(url, {
					method: "POST",
					headers,
					body: JSON.stringify({ query }),
				});
				if (!response.ok)
					throw new Error(`HTTP error! status: ${response.status}`);
				const result = await response.json();

				// Sort the menus based on Display_Order
				const sortedMenus = (result.data.menus || []).sort(
					(a, b) => a.Display_Order - b.Display_Order
				);

				// Sort each sub_menu's Sub_Menu based on Display_Order
				sortedMenus.forEach((menu) => {
					if (menu.sub_menu && menu.sub_menu.Sub_Menu) {
						menu.sub_menu.Sub_Menu.sort(
							(a, b) => a.Display_Order - b.Display_Order
						);
					}
				});

				setMenus(sortedMenus);
			} catch (error) {
				console.error("Error fetching menu data:", error.message);
			}
		},
		[API_URL]
	);

	// Handle Multi bahasa di menu
	useEffect(() => {
		fetchMenus(language);
		fetchLogo(language);
	}, [language, fetchMenus, fetchLogo, API_URL]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (navRef.current && !navRef.current.contains(event.target)) {
				setIsDropdownOpen(false);
				setActiveDropdown("");
				setIsMobileMenuOpen(false); // Close mobile menu when clicking outside
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [navRef]);

	// Handle Rander Gambar
	useEffect(() => {
		if (menus.length > 0) {
			menus.forEach((menu) => {
				if (menu.sub_menu && menu.sub_menu.Banner_Submenu) {
					const img = new Image();
					img.src = `${API_URL}${
						menu?.sub_menu?.Banner_Submenu?.formats?.thumbnail?.url ||
						menu?.sub_menu?.Banner_Submenu?.url
					}`;
				}
			});
		}
	}, [menus, API_URL]);

	// Dropdown
	const handleDropdownToggle = (dropdown) => {
		setActiveDropdown(activeDropdown === dropdown ? "" : dropdown);
		setIsDropdownOpen(activeDropdown !== dropdown || !isDropdownOpen);
	};

	const handleLanguageToggle = (lang) => {
		toggleLanguage(lang);
		setIsDropdownOpen(false);
		setActiveDropdown("");
		setIsMobileMenuOpen(false);
	};

	return (
		<nav
			ref={navRef}
			className="bg-white shadow-md fixed w-full z-50 font-helvetica"
		>
			<div className="container mx-auto px-4 flex items-center py-6">
				<Link
					to="/beranda"
					className="flex-shrink-0"
					style={{ display: "inline-block" }}
				>
					<button style={{ background: "none", border: "none", padding: 0 }}>
						{logo && (
							<img
								src={`${API_URL}${logo?.formats?.thumbnail?.url || logo?.url}`}
								alt={logo.name}
								className="h-10"
							/>
						)}
					</button>
				</Link>
				<div className="flex-grow"></div>

				<button
					className="block md:hidden text-gray-700 focus:outline-none absolute right-6 top-1/2 transform -translate-y-1/2"
					onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
					style={{ fontSize: "24px", padding: "10px" }}
				>
					<i className="fas fa-bars"></i>
				</button>

				<button
					className="block md:block lg:hidden text-gray-700 focus:outline-none absolute right-6 top-1/2 transform -translate-y-1/2"
					onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
					style={{ fontSize: "24px", padding: "10px" }}
				>
					<i className="fas fa-bars"></i>
				</button>

				<div className="hidden lg:flex items-center space-x-6">
					{menus.map((menu) => (
						<React.Fragment key={menu.Name_Menu}>
							{menu.sub_menu ? (
								<button
									onClick={() => handleDropdownToggle(menu.Name_Menu)}
									className="text-gray-700 hover:text-blue-500 flex items-center lg:text-base font-helvetica"
								>
									{menu.Name_Menu} <i className="fas fa-chevron-down ml-1"></i>
								</button>
							) : (
								<Link
									to={`/${menu.Name_Menu.toLowerCase()}`}
									className="text-gray-700 hover:text-blue-500 lg:text-base font-helvetica"
								>
									{menu.Name_Menu}
								</Link>
							)}
						</React.Fragment>
					))}
					<div className="flex items-center space-x-2">
						<button
							onClick={() => handleLanguageToggle("en")}
							className={`text-gray-700 hover:text-blue-500 lg:text-base font-helvetica ${
								language === "en" ? "font-bold" : ""
							}`}
						>
							EN
						</button>
						<span>|</span>
						<button
							onClick={() => handleLanguageToggle("id")}
							className={`text-gray-700 hover:text-blue-500 lg:text-lg font-helvetica ${
								language === "id" ? "font-bold" : ""
							}`}
						>
							ID
						</button>
					</div>
				</div>
			</div>
			{/* Tablet Menu */}
			{isMobileMenuOpen && (
				<div className="lg:hidden absolute top-full left-0 w-full bg-white shadow-lg font-satoshi">
					<div className="flex flex-col items-start space-y-4 py-4">
						{menus.map((menu) =>
							menu.sub_menu ? (
								<>
									<button
										onClick={() => handleDropdownToggle(menu.Name_Menu)}
										className="text-gray-700 hover:text-blue-500 flex justify-between w-full px-6 ml-4"
									>
										{menu.Name_Menu}
										<i
											className={`fas fa-chevron-down ${
												activeDropdown === menu.Name_Menu && isDropdownOpen
													? "rotate-180"
													: ""
											}`}
											style={{ position: "absolute", right: "22px" }}
										></i>
									</button>
									{activeDropdown === menu.Name_Menu && isDropdownOpen && (
										<div className="w-full px-6 space-y-2">
											{menu.sub_menu.Sub_Menu.map((submenu, index) => {
												// Filter sub-menu based on the last index in "Bisnis"
												const isLastBusinessSubMenu =
													(menu.Name_Menu === "Bisnis" ||
														menu.Name_Menu === "Business") &&
													index === menu.sub_menu.Sub_Menu.length - 1 &&
													!showNewBusinessPage;

												if (isLastBusinessSubMenu) {
													return null;
												}

												return (
													<Link
														to={submenu.Code_Page || "#"}
														key={submenu.Submenu}
														className="block text-gray-700 hover:text-blue-500 text-left pl-8"
													>
														{submenu.Submenu}
													</Link>
												);
											})}
										</div>
									)}
								</>
							) : (
								<Link
									to={`/${menu.Name_Menu.toLowerCase()}`}
									className="text-gray-700 hover:text-blue-500 flex justify-between w-full px-6 ml-4"
									key={menu.Name_Menu}
								>
									{menu.Name_Menu}
								</Link>
							)
						)}
						<div className="flex items-center space-x-2 px-6">
							<button
								onClick={() => handleLanguageToggle("en")}
								className={`text-gray-700 hover:text-blue-500 flex justify-between w-full ml-4 ${
									language === "en" ? "font-bold" : ""
								}`}
							>
								EN
							</button>
							<span>|</span>
							<button
								onClick={() => handleLanguageToggle("id")}
								className={`text-gray-700 hover:text-blue-500 flex justify-between w-full ml-4 ${
									language === "id" ? "font-bold" : ""
								}`}
							>
								ID
							</button>
						</div>
					</div>
				</div>
			)}
			{/* Mobile Content */}
			{isMobileMenuOpen && (
				<div className="lg:hidden md:hidden absolute top-full left-0 w-full bg-white shadow-lg font-satoshi">
					<div className="flex flex-col items-start space-y-4 py-4">
						{menus.map((menu) =>
							menu.sub_menu ? (
								<>
									<button
										onClick={() => handleDropdownToggle(menu.Name_Menu)}
										className="text-gray-700 hover:text-blue-500 flex justify-between w-full px-6"
									>
										{menu.Name_Menu}
										<i
											className={`fas fa-chevron-down ${
												activeDropdown === menu.Name_Menu && isDropdownOpen
													? "rotate-180"
													: ""
											}`}
										></i>
									</button>
									{activeDropdown === menu.Name_Menu && isDropdownOpen && (
										<div className="w-full px-6 space-y-2">
											{menu.sub_menu.Sub_Menu.map((submenu, index) => {
												// Filter sub-menu based on the last index in "Bisnis"
												const isLastBusinessSubMenu =
													(menu.Name_Menu === "Bisnis" ||
														menu.Name_Menu === "Business") &&
													index === menu.sub_menu.Sub_Menu.length - 1 &&
													!showNewBusinessPage;

												if (isLastBusinessSubMenu) {
													return null;
												}

												return (
													<Link
														to={submenu.Code_Page || "#"}
														key={submenu.Submenu}
														className="block text-gray-700 hover:text-blue-500 text-left pl-4"
													>
														{submenu.Submenu}
													</Link>
												);
											})}
										</div>
									)}
								</>
							) : (
								<Link
									to={`/${menu.Name_Menu.toLowerCase()}`}
									className="text-gray-700 hover:text-blue-500 flex justify-between w-full px-6"
									key={menu.Name_Menu}
								>
									{menu.Name_Menu}
								</Link>
							)
						)}
						<div className="flex items-center space-x-2 px-6">
							<button
								onClick={() => handleLanguageToggle("en")}
								className={`text-gray-700 hover:text-blue-500 ${
									language === "en" ? "font-bold" : ""
								}`}
							>
								EN
							</button>
							<span>|</span>
							<button
								onClick={() => handleLanguageToggle("id")}
								className={`text-gray-700 hover:text-blue-500 ${
									language === "id" ? "font-bold" : ""
								}`}
							>
								ID
							</button>
						</div>
					</div>
				</div>
			)}
			{/* Dropdown Content */}
			{isDropdownOpen && (
				<div className="hidden md:hidden lg:block w-full bg-white shadow-lg mt-1 font-satoshi">
					<div className="container mx-auto px-4 py-6 flex gap-8">
						{menus.map((menu) =>
							activeDropdown === menu.Name_Menu && menu.sub_menu ? (
								<div
									className="flex-1 flex items-start"
									key={menu.Name_Menu}
								>
									<div className="flex-1 text-left">
										<h3 className="text-xl font-semibold mb-2">
											{menu.sub_menu.Name_SubMenu}
										</h3>
										<p className="text-gray-600 mb-4 max-w-xs">
											{menu.sub_menu.Description_Submenu}
										</p>
									</div>
									<div className="flex-1 space-y-4 text-left">
										{menu.sub_menu.Sub_Menu.map((submenu, index) => {
											// Filter sub-menu based on the last index in "Bisnis"
											const isLastBusinessSubMenu =
												(menu.Name_Menu === "Bisnis" ||
													menu.Name_Menu === "Business") &&
												index === menu.sub_menu.Sub_Menu.length - 1 &&
												!showNewBusinessPage;

											if (isLastBusinessSubMenu) {
												return null;
											}

											return (
												<Link
													to={submenu.Code_Page || "#"}
													key={submenu.Submenu}
													className="block text-gray-700 hover:text-blue-500 text-left"
												>
													{submenu.Submenu}
												</Link>
											);
										})}
									</div>
									<img
										src={`${API_URL}${
											menu?.sub_menu?.Banner_Submenu?.formats?.thumbnail?.url ||
											menu?.sub_menu?.Banner_Submenu?.url
										}`}
										alt={menu.sub_menu.Name_SubMenu}
										loading="lazy"
										className="object-cover rounded-md ml-4"
										style={{ width: "20%", height: "80%" }}
									/>
								</div>
							) : null
						)}
					</div>
				</div>
			)}
		</nav>
	);
}

export default Navbar;
