import React, { useState, useEffect, useCallback } from "react";
import { useLanguage } from "../../../context/LanguageContext";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// Komponen MarkdownRenderer
const MarkdownRenderer = ({ content }) => (
	<ReactMarkdown
		remarkPlugins={[remarkGfm]}
		className="text-gray-700 mb-6 text-justify font-helvetica"
		components={{
			h1: ({ node, ...props }) => (
				<h1
					className="lg:text-5xl md:text-4xl sm:text-2xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 1 Content"}
				</h1>
			),
			h2: ({ node, ...props }) => (
				<h2
					className="lg:text-4xl md:text-3xl sm:text-xl text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 2 Content"}
				</h2>
			),
			h3: ({ node, ...props }) => (
				<h3
					className="lg:text-3xl md:text-2xl sm:text-large text-gray-800 mt-4 mb-2 font-bold font-helvetica"
					{...props}
				>
					{props.children || "Default Heading 3 Content"}
				</h3>
			),
			p: ({ node, ...props }) => (
				<p
					className="lg:text-xl md:text-xl text-base text-gray-700 mt-2 mb-4 font-helvetica"
					{...props}
				>
					{props.children || "Default paragraph content"}
				</p>
			),
			table: ({ node, ...props }) => (
				<table
					className="w-full border-separate border-spacing-0 my-4 border border-black font-helvetica"
					{...props}
				/>
			),
			th: ({ node, ...props }) => (
				<th
					className="p-2 border border-black text-left font-semibold bg-gray-200 font-helvetica"
					{...props}
				/>
			),
			td: ({ node, ...props }) => (
				<td
					className="p-2 border border-black text-left font-helvetica"
					{...props}
				/>
			),
			img: ({ node, ...props }) => (
				<img
					className="max-w-full h-auto my-4"
					loading="lazy"
					alt={props.alt || "Description of image"}
					{...props}
				/>
			),
			em: ({ node, ...props }) => (
				<em
					className="italic font-helvetica"
					{...props}
				/>
			),
			strong: ({ node, ...props }) => (
				<strong
					className="font-bold font-helvetica"
					{...props}
				/>
			),
			a: ({ node, ...props }) => (
				<a
					className="text-blue-600 hover:underline font-helvetica"
					{...props}
				>
					{props.children || "Link text"}
				</a>
			),
			// Tambahkan komponen untuk underline
			u: ({ node, ...props }) => (
				<u
					className="underline font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk bullet list (ul)
			ul: ({ node, ...props }) => (
				<ul
					className="list-disc ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk penomoran (ol)
			ol: ({ node, ...props }) => (
				<ol
					className="list-decimal ml-6 mb-4 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk list item (li)
			li: ({ node, ...props }) => (
				<li
					className="mb-1 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk blockquote
			blockquote: ({ node, ...props }) => (
				<blockquote
					className="border-l-4 border-gray-400 pl-4 italic text-gray-600 font-helvetica"
					{...props}
				/>
			),
			// Tambahkan komponen untuk code block
			code: ({ node, inline, className, children, ...props }) => {
				return inline ? (
					<code
						className="bg-gray-100 p-1 rounded font-mono text-sm"
						{...props}
					>
						{children}
					</code>
				) : (
					<pre className="bg-gray-800 text-white p-4 rounded my-4 overflow-x-auto">
						<code {...props}>{children}</code>
					</pre>
				);
			},
		}}
	>
		{content}
	</ReactMarkdown>
);

// Komponen ImageSlider
const ImageSlider = ({ images }) => {
	const API_URL = process.env.REACT_APP_API_URL;
	return (
		<Swiper
			modules={[Autoplay, Pagination]}
			autoplay={{ delay: 3000, disableOnInteraction: false }}
			pagination={{ clickable: true }}
			slidesPerView={1}
			className="w-full"
		>
			{images.map((imageObj, index) => (
				<SwiperSlide key={index}>
					<img
						src={`${API_URL}${imageObj?.Image?.url}`}
						alt={imageObj?.Title || "Image"}
						className="w-full h-64 object-cover rounded-lg" // Menambah class untuk ukuran konsisten
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

// Komponen Utama
const ShipmentLNG = () => {
	const { language } = useLanguage();
	const [data, setData] = useState(null);
	const [seoData, setSeoData] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);
	const [activeTab, setActiveTab] = useState("specification");

	const API_URL = process.env.REACT_APP_API_URL;

	const fetchGeneralSEOData = useCallback(async () => {
		const query = `
            query {
                generalPage {
                    SEO {
                        MetaTitle
                        MetaDescription
                        ShareImage { url }
                        MetaSocial {
                            Title
                            Social_Network
                            Description
                            Image { url }
                        }
                        Keywords
                        MetaRobots
                        StructuredData
                        MetaViewPort
                        CanonicalURL
                    }
                }
            }
        `;

		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			setSeoData(result.data.generalPage.SEO);
		} catch (error) {
			console.error("Error fetching general SEO data:", error.message);
		}
	}, [API_URL]);

	const fetchShareholding = useCallback(async () => {
		const query = `
      query {
        shipmentLngPage(locale: "${language}") {
          Title
          Description
          Business_Images {
            Title
            Image {
              url
              formats
            }
          }
          Ships {
            Title
            Banner_Image {
              Title
              Image {
                formats
                url
              }
            }
            Specification
            Principal
          }
          SEO {
            Use_GeneralSEO
            SEO {
              MetaTitle
              MetaDescription
              ShareImage { url }
              MetaSocial {
                Title
                Social_Network
                Description
                Image { url }
              }
              Keywords
              MetaRobots
              StructuredData
              MetaViewPort
              CanonicalURL
            }
          }
        }
      }
    `;
		try {
			const response = await fetch(`${API_URL}/graphql`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
				},
				body: JSON.stringify({ query }),
			});
			if (!response.ok)
				throw new Error(`HTTP error! status: ${response.status}`);
			const result = await response.json();
			const fetchShareholding = result.data.shipmentLngPage;
			setData(fetchShareholding);

			if (fetchShareholding.SEO.Use_GeneralSEO) {
				fetchGeneralSEOData();
			} else {
				setSeoData(fetchShareholding.SEO.SEO);
			}
		} catch (error) {
			console.error("Error fetching overview data:", error.message);
		}
	}, [API_URL, language, fetchGeneralSEOData]);

	const setSEOTags = () => (
		<Helmet>
			<title>{seoData?.MetaTitle || data?.Title || "Default Title"}</title>
			<meta
				name="description"
				content={seoData?.MetaDescription || "Default description"}
			/>
			<meta
				name="keywords"
				content={seoData?.Keywords || "default, keywords, SEO"}
			/>
			<meta
				name="viewport"
				content={
					seoData?.MetaViewPort || "width=device-width, initial-scale=1.0"
				}
			/>
			<link
				rel="canonical"
				href={seoData?.CanonicalURL || window.location.href}
			/>
			{seoData?.ShareImage && (
				<meta
					property="og:image"
					content={`${API_URL}${seoData.ShareImage.url}`}
				/>
			)}
			<meta
				name="robots"
				content={seoData?.MetaRobots || "index, follow"}
			/>
			{seoData?.StructuredData && (
				<script type="application/ld+json">
					{JSON.stringify(seoData.StructuredData)}
				</script>
			)}
		</Helmet>
	);

	useEffect(() => {
		fetchShareholding();
	}, [fetchShareholding]);

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	if (!data) {
		return (
			<div className="flex gap-2 justify-center items-center h-screen">
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
				<div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
			</div>
		);
	}

	return (
		<>
			<div className="w-full h-full p-8 relative z-20 px-4 lg:px-24 font-helvetica">
				{setSEOTags()}
				<div className="container mx-auto p-2 lg:p-4 lg:mt-6 md:mt-6 mt-2">
					<section className="mb-10">
						{/* <h2
							className="text-2xl md:text-4xl lg:text-4xl font-bold lg:mb-6 lg:mb-6 mb-4 text-left text-blue-900"
							style={{
								color: "#2E3491",
								borderLeft: "8px solid red",
								paddingLeft: "10px",
							}}
						>
							{data.Title}
						</h2> */}
						<img
							src={`${API_URL}${
								data.Business_Images.Image?.large?.url ||
								data.Business_Images.Image?.url
							}`}
							alt={data.Business_Images.Title}
							className="w-full h-auto rounded-lg mb-4"
							style={{
								width: "100%",
								height: "auto",
								objectFit: "cover",
								maxHeight: "50vh", // Membatasi tinggi maksimal agar tidak terlalu tinggi di layar
							}}
						/>
						<MarkdownRenderer content={data.Description} />
					</section>
					<section className="w-full">
						<div className="border border-gray-300 rounded-md">
							{data?.Ships?.map((ship, index) => (
								<div
									key={index}
									className="border-b border-gray-300 cursor-pointer"
									onClick={() => toggleAccordion(index)}
									style={{
										borderBottomWidth: "1px",
										borderTopWidth: "2px",
										borderRightWidth: "2px",
										borderLeftWidth: "2px",
										borderColor: "#2E3491",
									}}
								>
									<div className="flex justify-between p-4 text-large md:text-2xl lg:text-2xl font-semibold text-blue-900 font-helvetica">
										{ship.Title}
										<span>{activeIndex === index ? "▲" : "▼"}</span>
									</div>

									{activeIndex === index && (
										<div className="p-3 border-t-2 border-blue-900">
											{/* Images Section with Swapper */}
											<div className="md:hidden mb-4">
												<ImageSlider images={ship.Banner_Image} />
											</div>
											{/* Images Section for Desktop */}
											<div className="hidden md:flex justify-center overflow-x-auto space-x-4 mb-4">
												{ship?.Banner_Image?.map((imageObj, imgIndex) => (
													<img
														key={imgIndex}
														src={`${API_URL}${imageObj.Image.url}`}
														alt={imageObj.Title || "Image"}
														className="w-80 h-auto rounded-lg"
													/>
												))}
											</div>

											<div className="bg-transparent rounded-lg p-4">
												<h2 className="text-blue-900 font-semibold text-left mb-4 text-lg sm:text-xl">
													{language === "en"
														? "Specification & Principal Dimensions"
														: "Spesifikasi & Dimensi Utama"}
												</h2>
												{/* Filter buttons and table content */}
												<div className="flex flex-col lg:flex-row lg:space-x-4">
													<div className="flex flex-col sm:flex-row lg:flex-col mb-4 space-y-2 sm:space-y-0 sm:space-x-4 lg:space-x-0 lg:space-y-2">
														<button
															className={`${
																activeTab === "specification"
																	? "bg-blue-500 text-white"
																	: "bg-gray-300 text-blue-900"
															} py-2 px-4 rounded-lg font-medium w-full`}
															onClick={(e) => {
																e.stopPropagation();
																setActiveTab("specification");
															}}
														>
															{language === "en"
																? "Specification"
																: "Spesifikasi"}
														</button>
														<button
															className={`${
																activeTab === "dimensions"
																	? "bg-blue-500 text-white"
																	: "bg-gray-300 text-blue-900"
															} py-2 px-4 rounded-lg font-medium w-full`}
															onClick={(e) => {
																e.stopPropagation();
																setActiveTab("dimensions");
															}}
														>
															{language === "en"
																? "Principal Dimension"
																: "Dimensi Utama"}
														</button>
													</div>
													{/* Table Content */}
													<div className="bg-white p-4 rounded-lg shadow-sm border border-gray-300 flex-grow">
														{activeTab === "dimensions" ? (
															<table className="w-full text-blue-900">
																<tbody>
																	<tr>
																		<MarkdownRenderer
																			content={ship.Principal}
																		/>
																	</tr>
																</tbody>
															</table>
														) : (
															<table className="w-full text-blue-900">
																<tbody>
																	<tr>
																		<MarkdownRenderer
																			content={ship.Specification}
																		/>
																	</tr>
																</tbody>
															</table>
														)}
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							))}
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default ShipmentLNG;
